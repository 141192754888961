const CONFIG: any = {
  LAPUTAN_API_URL: process.env.GATSBY_LAPUTAN_CF_API_URL || '',
  LAPUTAN_STATIC_HOST_URL: process.env.GATSBY_LAPUTAN_STATIC_HOST_URL || '',
  LAPUTAN_IMAGE_SERVER_URL: process.env.GATSBY_LAPUTAN_IMAGE_SERVER_URL || '',
  WEBSITE_AUTHOR: process.env.GATSBY_WEBSITE_AUTHOR,
  WEBSITE_TITLE: process.env.GATSBY_WEBSITE_TITLE,
  WEBSITE_DESCRIPTION: process.env.GATSBY_WEBSITE_DESCRIPTION,
  WEBSITE_SITEURL: process.env.GATSBY_WEBSITE_SITEURL,
  AWS_AUTH: {
    Auth: {
      region: process.env.GATSBY_AUTH_REGION, // region
      userPoolId: process.env.GATSBY_USER_POOL_ID, // aka USER_POOL_ID - General settings -> Pool Id
      userPoolWebClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID, // aka APP_CLIENT_ID - User Pools -> General Settings -> App clients -> App client id
      identityPoolId: process.env.GATSBY_AUTH_IDENTITY_POOL_ID, // aka IDENTITY_POOL_ID Federated Identities -> Identity Pool -> Dashboard
      oauth: {
        domain: process.env.GATSBY_OAUTH_DOMAIN,
        // scope: ['email', 'profile'],
        redirectSignIn: process.env.GATSBY_OAUTH_REDIRECT_SIGN_IN_URL,
        redirectSignOut: process.env.GATSBY_OAUTH_REDIRECT_SIGN_OUT_URL,
        responseType: 'token',
      },
    },
  },
  ENABLE_APOLLO_AUTOMATIC_PERSISTED_QUERIES:
    process.env.GATSBY_ENABLE_APOLLO_AUTOMATIC_PERSISTED_QUERIES === 'true',
  FACEBOOK_APP_ID: process.env.GATSBY_FACEBOOK_APP_ID || undefined,
  STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
  DEFAULT_LOGGED_IN_PAGE: process.env.GATSBY_DEFAULT_LOGGED_IN_PAGE,
  DEFAULT_LOGGED_OUT_PAGE: process.env.GATSBY_DEFAULT_LOGGED_OUT_PAGE,
  APP_URL: process.env.GATSBY_WEBSITE_SITEURL,
}

CONFIG.WELCOME_PAGE = '/consulting/welcome'

export { CONFIG }
