import { ac } from '../../actions'

export const GET_POPULAR_SUBURBS_BY_STATE = 'GET_POPULAR_SUBURBS_BY_STATE'
export const GET_POPULAR_SUBURBS_BY_STATE_SUCCESS =
  'GET_POPULAR_SUBURBS_BY_STATE_SUCCESS'
export const GET_POPULAR_SUBURBS_BY_STATE_FAILED =
  'GET_POPULAR_SUBURBS_BY_STATE_FAILED'

export const getPopularSuburbByState = ({ state }: { state: string }) =>
  ac(GET_POPULAR_SUBURBS_BY_STATE, {
    state,
  })
