import { SagaRequestHelper } from './SagaRequestHelper'
import { SubscriptionRequestHelper } from '../RequestHelper/SubscriptionRequestHelper'

const SubscriptionSagaRequestHelper = {
  request: function*(props: any) {
    yield SagaRequestHelper.request({
      requester: SubscriptionRequestHelper.request,
      ...props,
    })
  },
}

export { SubscriptionSagaRequestHelper }
