import { mergeDeepRight } from '../lib/ramda'
import { StorageHelper } from './StorageHelper'
import { overrideState, preloadedState } from '../redux/reducers'

const STORE_KEY = 'redux_state'

const StateHelper = {
  persistState: (state: any) => {
    try {
      StorageHelper.setItem(STORE_KEY, state)
    } catch (err) {
      console.log('Failed to persist state', err)
    }
  },

  hydrateState: () => {
    try {
      const item = StorageHelper.getItem(STORE_KEY)
      return item ? mergeDeepRight(item, overrideState) : preloadedState
    } catch (err) {
      console.log('Failed to hydrate state', StorageHelper.getItem(STORE_KEY))
      return undefined
    }
  },
}

export { StateHelper }
