import { takeLatest, put } from 'redux-saga/effects'
import { ac } from '../actions'
import {
  CHANGE_PASSWORD,
  COMPLETE_NEW_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUBMIT,
  GET_CURRENT_USER_INFO,
  GET_CURRENT_USER_INFO_FAILED,
  GET_CURRENT_USER_INFO_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  RESEND_SIGN_UP_CONFIRMATION,
  UPDATE_CURRENT_USER_PROFILE,
  UPDATE_CURRENT_USER_PROFILE_FAILED,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
} from './actions'
import { NavigationHelper } from '../../helpers/NavigationHelper'
import { AuthHelper } from '../../helpers/AuthHelper'
import { showAlert, showErrorAlert, showSuccessAlert } from '../alerts/actions'
import { hideLoadingBar, showLoadingBar } from '../loadingBar/actions'
import { LocaleHelper } from '../../helpers/LocaleHelper'
import { CONFIG } from '../../config'
import { hideModal, showModal } from '../modals/actions'
import { ModalType } from '../modals/ModalType.constants'
import { AdminRequestHelper } from '../../helpers/RequestHelper/AdminRequestHelper'

const STATUS = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  PASSWORD_RESET_REQUIRED: 'PasswordResetRequiredException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
}

export function* performGetCurrentUserInfo() {
  try {
    const {
      getCurrentUserInfo: { user: userInfo },
    } = yield AdminRequestHelper.request(`
      query {
        getCurrentUserInfo {
          user {
            id
            name
            email
            company
            role
          }
        }
      }
    `)

    yield put(ac(GET_CURRENT_USER_INFO_SUCCESS, { user: userInfo }))
  } catch (ex) {
    yield put(ac(GET_CURRENT_USER_INFO_FAILED))
  }
}

function* performLogin(action: any) {
  yield put(showLoadingBar())

  const {
    payload: { email, password },
  } = action
  try {
    // @ts-ignore
    const user = yield AuthHelper.signIn({
      username: email,
      password,
    })

    AuthHelper.tempUser = user

    const { challengeName } = user
    if (challengeName === STATUS.NEW_PASSWORD_REQUIRED) {
      yield put(showModal(ModalType.NEW_PASSWORD_REQUIRED))
      yield put(hideLoadingBar())
      return
    }

    const {
      signInUserSession: {
        idToken: { jwtToken },
        refreshToken: { token: refreshToken },
      },
    } = user

    const userInfo = {
      jwtToken,
      email,
      refreshToken,
    }
    yield put(hideModal())
    yield put(ac(LOGIN_SUCCESS, { userInfo }))

    yield performGetCurrentUserInfo()
    if (CONFIG.DEFAULT_LOGGED_IN_PAGE) {
      NavigationHelper.navigate(CONFIG.DEFAULT_LOGGED_IN_PAGE)
    }
  } catch (ex) {
    console.log('exception', ex)
    yield put(ac(LOGIN_FAILED, {}))
    const { code } = ex
    if (code === STATUS.USER_NOT_CONFIRMED_EXCEPTION) {
      yield put(
        showModal(ModalType.CONFIRM_EMAIL, {
          email,
        })
      )
    } else if (code === STATUS.PASSWORD_RESET_REQUIRED) {
      yield put(showModal(ModalType.CHANGE_PASSWORD))
    } else {
      yield put(showAlert(LocaleHelper.getText(code)))
    }
  }
  yield put(hideLoadingBar())
}

function performLogout() {
  if (CONFIG.DEFAULT_LOGGED_OUT_PAGE) {
    NavigationHelper.navigate('/')
  }
}

export function* performUpdateCurrentUserProfile(action: any) {
  try {
    const {
      payload: { user },
    } = action
    const {
      updateCurrentUserProfile: { user: updatedUser },
    } = yield AdminRequestHelper.request(
      `
      mutation UpdateCurrentUserProfile(
        $name: String,
        $company: String,
      ) {
        updateCurrentUserProfile(user: {
          name: $name
          company: $company
        }) {
          user {
            id
            name
            email
            company
          }
        }
       } 
    `,
      user
    )

    yield put(ac(UPDATE_CURRENT_USER_PROFILE_SUCCESS, { user: updatedUser }))
    yield put(
      showSuccessAlert(
        LocaleHelper.getText('UPDATED_USER_PROFILE_SUCCESSFULLY')
      )
    )
  } catch (ex) {
    yield put(ac(UPDATE_CURRENT_USER_PROFILE_FAILED))
    yield put(
      showErrorAlert(LocaleHelper.getText('FAILED_TO_UPDATE_USER_PROFILE'))
    )
  }
}

export function* performCompleteNewPassword(action: any) {
  const {
    payload: { password },
  } = action
  try {
    const user = AuthHelper.tempUser
    yield AuthHelper.completeNewPassword(
      user,
      password,
      user.challengeParam.requiredAttributes
    )
    delete AuthHelper.tempUser
    yield put(showSuccessAlert('Your password has been updated'))
    yield put(hideModal())
  } catch (ex) {
    console.log('ex', ex)
    yield put(showErrorAlert(ex.message))
  }
}

// WIP
export function* performChangePassword(action: any) {
  const {
    payload: { oldPassword, newPassword },
  } = action
  try {
    const user = AuthHelper.tempUser
    const data = AuthHelper.changePassword(user, oldPassword, newPassword)
    delete AuthHelper.tempUser
    console.log('data', data)
    yield put(showSuccessAlert('Your password has been updated'))
    yield put(hideModal())
  } catch (ex) {
    yield put(showErrorAlert(ex))
  }
}

export function* performForgotPassword(action: any) {
  const {
    payload: { email },
  } = action

  try {
    yield AuthHelper.forgotPassword(email)
    yield put(showSuccessAlert('Verification code has sent to your email'))
  } catch (ex) {
    console.log('error', ex)
    yield put(showErrorAlert(ex.message))
  }
}

export function* performForgotPasswordSubmit(action: any) {
  const {
    payload: { email, newPassword, verificationCode },
  } = action
  try {
    yield AuthHelper.forgotPasswordSubmit(email, verificationCode, newPassword)
    yield put(
      showSuccessAlert(
        'Your password has updated. Please log in with your new password.'
      )
    )
    yield put(hideModal())
  } catch (ex) {
    console.log('error', ex)
    yield put(showErrorAlert(ex.message))
  }
}

export function* performResendSignUpConfirmation(action: {
  payload: { email: string }
}) {
  const {
    payload: { email },
  } = action
  try {
    yield AuthHelper.resendSignUp(email)
  } catch (ex: any) {
    console.log('error', ex)
    yield put(showErrorAlert(ex.message))
  }
}

/* Watchers */
function* watchLogin() {
  yield takeLatest(LOGIN, performLogin)
}

function* watchLogout() {
  yield takeLatest(LOGOUT, performLogout)
}

function* watchGetCurrentUserInfo() {
  yield takeLatest(GET_CURRENT_USER_INFO, performGetCurrentUserInfo)
}

function* watchUpdateCurrentUserProfile() {
  yield takeLatest(UPDATE_CURRENT_USER_PROFILE, performUpdateCurrentUserProfile)
}

function* watchCompleteNewPassword() {
  yield takeLatest(COMPLETE_NEW_PASSWORD, performCompleteNewPassword)
}

function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, performChangePassword)
}

function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, performForgotPassword)
}

function* watchForgotPasswordSubmit() {
  yield takeLatest(FORGOT_PASSWORD_SUBMIT, performForgotPasswordSubmit)
}

function* watchResendSignUpConfirmationCode() {
  yield takeLatest(RESEND_SIGN_UP_CONFIRMATION, performResendSignUpConfirmation)
}

export default [
  watchLogin(),
  watchLogout(),
  watchGetCurrentUserInfo(),
  watchUpdateCurrentUserProfile(),
  watchCompleteNewPassword(),
  watchChangePassword(),
  watchForgotPassword(),
  watchForgotPasswordSubmit(),
  watchResendSignUpConfirmationCode(),
]
