import { HIDE_MODAL, SHOW_MODAL } from './actions'
import { mergeRight } from '../../lib/ramda'
import { School } from '../../containers/props/School.types'

interface IPaymentModalState {
  school: School
}

export interface ModalState {
  modalType: string | null
  data: IPaymentModalState | any
}

const initialState = {
  modalType: null,
  data: null,
}

const modals = (state = initialState, { type, payload }: any): ModalState => {
  switch (type) {
    case SHOW_MODAL:
      return mergeRight(state, {
        modalType: payload.modalType,
        data: payload.data,
      })
    case HIDE_MODAL:
      return mergeRight(state, { modalType: null })
    default:
      return state
  }
}

export { modals }
