import React from 'react'
import { ac } from '../actions'
import { ModalType } from './ModalType.constants'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const showModal = (modalType: ModalType, data?: any) =>
  ac(SHOW_MODAL, { modalType, data })
export const showMessageModal = ({
  header,
  message,
}: {
  header: React.ReactNode
  message: React.ReactNode
}) => showModal(ModalType.MESSAGE, { header, message })
export const hideModal = () => ac(HIDE_MODAL)
