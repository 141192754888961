import { navigate } from 'gatsby'
import { hideModal } from '../../states/modals/actions'
import { getWindow, getLocation } from '../UtilHelper'

const OAUTH_LATEST_PATH_AROUND_SCHOOL = 'oauth_latest_path_around_school'

export class NavigationHelper {
  public static navigate(to: string, opt?: any) {
    const { store } = require('../../states/store')
    if (getWindow()) {
      navigate(to, opt)
    }
    store.dispatch(hideModal())
  }

  public static saveLatestPath() {
    let latestPath = ''
    let key = OAUTH_LATEST_PATH_AROUND_SCHOOL

    const win = getWindow()
    const location = getLocation()
    if (location) {
      const { pathname, search } = location
      latestPath = `${pathname}${search}`
    }

    if (latestPath !== '' && win) {
      win.localStorage.setItem(key, latestPath)
    }
  }

  public static getLatestPath() {
    let latestPath = ''
    const win = getWindow()
    if (win) {
      // check if latest latest visited path before oauth has been saved
      let savedPath = win.localStorage.getItem(OAUTH_LATEST_PATH_AROUND_SCHOOL)
      if (savedPath) {
        latestPath = savedPath // set the latest visited path
      }
    }
    return latestPath
  }

  public static removeLatestPath() {
    return window.localStorage.removeItem(OAUTH_LATEST_PATH_AROUND_SCHOOL)
  }
}
