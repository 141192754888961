import { takeLatest, select, put } from 'redux-saga/effects'
import { SchoolAdminGraphql } from '@laputan/graphql/dist/SchoolAdmin.graphql'
import { SchoolsGraphql } from '@laputan/graphql/dist/Schools.graphql'
import { SchoolAdminSagaRequestHelper } from '../../helpers/SagaRequestHelper/SchoolAdminSagaRequestHelper'
import { CurrentUserState } from '../currentUser/reducer'
import { showSchoolLoginModal } from '../schools/actions'
import { showModal } from '../modals/actions'
import { ModalType } from '../modals/ModalType.constants'
import {
  BasicSchoolPlanPaymentIntentAction,
  CheckHasBasicManageAccessAction,
  GetSchoolPlanFeesAction,
  ShowEditSchoolModalAction,
  GetPaidInvoicesAction,
} from '../../types/SchoolAdmin.types'
import { SchoolAdminRequestHelper } from '../../helpers/RequestHelper/SchoolAdminRequestHelper'
import { PaymentAction } from './types'
import { getSchoolPlanFeesAction } from './actions'
import { SchoolSagaRequestHelper } from '../../helpers/SagaRequestHelper/SchoolSagaRequestHelper'

function* performBasicSchoolPlanPaymentIntent(
  action: BasicSchoolPlanPaymentIntentAction
) {
  yield SchoolAdminSagaRequestHelper.request({
    action,
    graphql: SchoolAdminGraphql.CreateSchoolBasicPlanPaymentIntent,
  })
}

function* performGetSchoolPlanFees(action: GetSchoolPlanFeesAction) {
  yield SchoolSagaRequestHelper.request({
    action,
    graphql: SchoolsGraphql.GetSchoolPlanFees,
  })
}

function* performCheckHasBasicManageAccess(
  action: CheckHasBasicManageAccessAction
) {
  yield SchoolAdminSagaRequestHelper.request({
    action,
    graphql: SchoolAdminGraphql.CheckHasSchoolBasicManageAccess,
  })
}

function* performShowEditSchoolModal({
  payload: { school },
}: ShowEditSchoolModalAction) {
  const { userInfo }: CurrentUserState = yield select(
    ({ currentUser }) => currentUser
  )
  const hasLoggedIn: boolean = !!userInfo

  if (!hasLoggedIn) {
    // log in modal
    yield put(showSchoolLoginModal())
    return
  }

  yield put(showModal(ModalType.LOADING))
  yield put(getSchoolPlanFeesAction())

  const {
    checkHasSchoolBasicManageAccess: { hasAccess },
  } = yield SchoolAdminRequestHelper.request(
    SchoolAdminGraphql.CheckHasSchoolBasicManageAccess,
    { schoolId: school.id }
  )

  if (!hasAccess) {
    yield put(showModal(ModalType.PAYMENT, { school }))
    return
  }
  yield put(showModal(ModalType.EDIT_SCHOOL, { school }))
}

function* performGetInvoices(action: GetPaidInvoicesAction) {
  yield SchoolAdminSagaRequestHelper.request({
    action,
    graphql: SchoolAdminGraphql.GetPaidInvoices,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(
    PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT,
    performBasicSchoolPlanPaymentIntent
  )
  yield takeLatest(PaymentAction.GET_SCHOOL_PLAN_FEES, performGetSchoolPlanFees)
  yield takeLatest(
    PaymentAction.CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS,
    performCheckHasBasicManageAccess
  )
  yield takeLatest(
    PaymentAction.SHOW_EDIT_SCHOOL_MODAL,
    performShowEditSchoolModal
  )
  yield takeLatest(PaymentAction.GET_PAID_INVOICES, performGetInvoices)
}

export default [watchers()]
