import { takeLatest } from 'redux-saga/effects'
import { ChangeRequestsGraphql } from '@laputan/graphql/dist/ChangeRequests.graphql'
import { CREATE_CHANGE_REQUEST } from './actions'
import { ChangeRequestSagaRequestHelper } from '../../../helpers/SagaRequestHelper/ChangeRequestSagaRequestHelper'

export function* performCreateChangeRequest(action: any) {
  yield ChangeRequestSagaRequestHelper.request({
    action,
    graphql: ChangeRequestsGraphql.CreateChangeRequest,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(CREATE_CHANGE_REQUEST, performCreateChangeRequest)
}

export default [watchers()]
