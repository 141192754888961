import { ac } from '../../actions'

export const GET_SCHOOL_LISTINGS_BY_SCHOOL_ID =
  'GET_SCHOOL_LISTINGS_BY_SCHOOL_ID'
export const GET_SCHOOL_LISTINGS_BY_SCHOOL_ID_SUCCESS =
  'GET_SCHOOL_LISTINGS_BY_SCHOOL_ID_SUCCESS'
export const GET_SCHOOL_LISTINGS_BY_SCHOOL_ID_FAILED =
  'GET_SCHOOL_LISTINGS_BY_SCHOOL_ID_FAILED'

export const getSchoolListingsBySchoolId = ({ schoolId }: any) =>
  ac(GET_SCHOOL_LISTINGS_BY_SCHOOL_ID, { schoolId })
