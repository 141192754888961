import {
  CLEAR_AUTO_SUGGEST,
  SCHOOL_AUTO_SUGGEST_SEARCH,
  SCHOOL_AUTO_SUGGEST_SEARCH_FAILED,
  SCHOOL_AUTO_SUGGEST_SEARCH_SUCCESS,
} from './actions'
import { STATE_TO_TEXT } from './autoSuggestStateData'

export interface SchoolsAutoSuggestState {
  isFetching: boolean
  states: Array<{ displayText: string; value: string }>
  schools: Array<any>
  localities: Array<any>
}

const initialState: SchoolsAutoSuggestState = {
  isFetching: false,
  states: STATE_TO_TEXT,
  schools: [],
  localities: [],
}

const schoolsAutoSuggest = (
  state: any = initialState,
  { type, payload }: any
): SchoolsAutoSuggestState => {
  switch (type) {
    case SCHOOL_AUTO_SUGGEST_SEARCH:
      return {
        ...state,
        isFetching: true,
      }
    case SCHOOL_AUTO_SUGGEST_SEARCH_SUCCESS: {
      const { schools, localities, states } = payload
      return {
        ...state,
        isFetching: false,
        states,
        schools,
        localities,
      }
    }
    case SCHOOL_AUTO_SUGGEST_SEARCH_FAILED: {
      return {
        ...state,
        isFetching: false,
        schools: [],
        localities: [],
      }
    }
    case CLEAR_AUTO_SUGGEST: {
      return initialState
    }
    default:
      return state
  }
}

export { schoolsAutoSuggest, initialState }
