exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-posts-index-tsx": () => import("./../../../src/pages/blog/posts/index.tsx" /* webpackChunkName: "component---src-pages-blog-posts-index-tsx" */),
  "component---src-pages-developers-embed-schools-tsx": () => import("./../../../src/pages/developers/embed/schools.tsx" /* webpackChunkName: "component---src-pages-developers-embed-schools-tsx" */),
  "component---src-pages-developers-index-tsx": () => import("./../../../src/pages/developers/index.tsx" /* webpackChunkName: "component---src-pages-developers-index-tsx" */),
  "component---src-pages-external-search-tsx": () => import("./../../../src/pages/externalSearch.tsx" /* webpackChunkName: "component---src-pages-external-search-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoices-tsx": () => import("./../../../src/pages/invoices.tsx" /* webpackChunkName: "component---src-pages-invoices-tsx" */),
  "component---src-pages-popular-suburbs-index-tsx": () => import("./../../../src/pages/popular/suburbs/index.tsx" /* webpackChunkName: "component---src-pages-popular-suburbs-index-tsx" */),
  "component---src-pages-price-activity-basic-plan-tsx": () => import("./../../../src/pages/price/activityBasicPlan.tsx" /* webpackChunkName: "component---src-pages-price-activity-basic-plan-tsx" */),
  "component---src-pages-price-school-basic-plan-tsx": () => import("./../../../src/pages/price/schoolBasicPlan.tsx" /* webpackChunkName: "component---src-pages-price-school-basic-plan-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-schools-details-tsx": () => import("./../../../src/pages/schools/details.tsx" /* webpackChunkName: "component---src-pages-schools-details-tsx" */),
  "component---src-pages-schools-search-tsx": () => import("./../../../src/pages/schools/search.tsx" /* webpackChunkName: "component---src-pages-schools-search-tsx" */),
  "component---src-pages-sitemap-index-tsx": () => import("./../../../src/pages/sitemap/index.tsx" /* webpackChunkName: "component---src-pages-sitemap-index-tsx" */),
  "component---src-pages-sitemap-states-index-tsx": () => import("./../../../src/pages/sitemap/states/index.tsx" /* webpackChunkName: "component---src-pages-sitemap-states-index-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/termsAndConditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

