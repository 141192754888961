import { ac } from '../actions'

export const SHOW_ALERT = 'SHOW_ALERT'
export const SHOW_INFO_ALERT = 'SHOW_INFO_ALERT'
export const SHOW_SUCCESS_ALERT = 'SHOW_SUCCESS_ALERT'
export const SHOW_WARNING_ALERT = 'SHOW_WARNING_ALERT'
export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT'
export const SHOW_URGENT_ALERT = 'SHOW_URGENT_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const HIDE_ALERTS = 'HIDE_ALERTS'

export const showAlert = (messageText: string) =>
  ac(SHOW_ALERT, { messageText })

export const showInfoAlert = (messageText: string) =>
  ac(SHOW_INFO_ALERT, { messageText })

export const showSuccessAlert = (messageText: string) =>
  ac(SHOW_SUCCESS_ALERT, { messageText })

export const showWarningAlert = (messageText: string) =>
  ac(SHOW_WARNING_ALERT, { messageText })

export const showErrorAlert = (messageText: string) =>
  ac(SHOW_ERROR_ALERT, { messageText })

export const showUrgentAlert = (messageText: string) =>
  ac(SHOW_URGENT_ALERT, { messageText })

export const hideAlert = (id: string) => ac(HIDE_ALERT, { id })

export const hideAlerts = () => ac(HIDE_ALERTS)
