import { ac } from '../../actions'

export const CONNECT_SEARCH_MEDIA_ITEMS = 'CONNECT_SEARCH_MEDIA_ITEMS'
export const CONNECT_SEARCH_MEDIA_ITEMS_SUCCESS =
  'CONNECT_SEARCH_MEDIA_ITEMS_SUCCESS'
export const CONNECT_SEARCH_MEDIA_ITEMS_FAILED =
  'CONNECT_SEARCH_MEDIA_ITEMS_FAILED'
export const CONNECT_GET_MEDIA_ITEM_BY_ID = 'CONNECT_GET_MEDIA_ITEM_BY_ID'
export const CONNECT_GET_MEDIA_ITEM_BY_ID_SUCCESS =
  'CONNECT_GET_MEDIA_ITEM_BY_ID_SUCCESS'
export const CONNECT_GET_MEDIA_ITEM_BY_ID_FAILED =
  'CONNECT_GET_MEDIA_ITEM_BY_ID_FAILED'
export const CONNECT_CREATE_MEDIA_ITEM = 'CONNECT_CREATE_MEDIA_ITEM'
export const CONNECT_CREATE_MEDIA_ITEM_SUCCESS =
  'CONNECT_CREATE_MEDIA_ITEM_SUCCESS'
export const CONNECT_CREATE_MEDIA_ITEM_FAILED =
  'CONNECT_CREATE_MEDIA_ITEM_FAILED'
export const CONNECT_CREATE_MEDIA_ITEMS = 'CONNECT_CREATE_MEDIA_ITEMS'
export const CONNECT_CREATE_MEDIA_ITEMS_SUCCESS =
  'CONNECT_CREATE_MEDIA_ITEMS_SUCCESS'
export const CONNECT_CREATE_MEDIA_ITEMS_FAILED =
  'CONNECT_CREATE_MEDIA_ITEMS_FAILED'
export const CONNECT_UPDATE_MEDIA_ITEM_BY_ID = 'CONNECT_UPDATE_MEDIA_ITEM_BY_ID'
export const CONNECT_UPDATE_MEDIA_ITEM_BY_ID_SUCCESS =
  'CONNECT_UPDATE_MEDIA_ITEM_BY_ID_SUCCESS'
export const CONNECT_UPDATE_MEDIA_ITEM_BY_ID_FAILED =
  'CONNECT_UPDATE_MEDIA_ITEM_BY_ID_FAILED'
export const CONNECT_REMOVE_MEDIA_ITEM_BY_ID = 'CONNECT_REMOVE_MEDIA_ITEM_BY_ID'
export const CONNECT_REMOVE_MEDIA_ITEM_BY_ID_SUCCESS =
  'CONNECT_REMOVE_MEDIA_ITEM_BY_ID_SUCCESS'
export const CONNECT_REMOVE_MEDIA_ITEM_BY_ID_FAILED =
  'CONNECT_REMOVE_MEDIA_ITEM_BY_ID_FAILED'
export const CONNECT_MEDIA_IMAGE_UPLOAD = 'CONNECT_MEDIA_IMAGE_UPLOAD'
export const CONNECT_MEDIA_IMAGE_UPLOAD_SUCCESS =
  'CONNECT_MEDIA_IMAGE_UPLOAD_SUCCESS'
export const CONNECT_MEDIA_IMAGE_UPLOAD_FAILED =
  'CONNECT_MEDIA_IMAGE_UPLOAD_FAILED'

export const connectSearchMediaItems = ({ page, domain, title }: any = {}) =>
  ac(CONNECT_SEARCH_MEDIA_ITEMS, { page, domain, title })
export const connectGetMediaItemById = ({ mediaItemId, success }: any) =>
  ac(CONNECT_GET_MEDIA_ITEM_BY_ID, { mediaItemId, success })
export const connectCreateMediaItem = ({ mediaItem, success }: any) =>
  ac(CONNECT_CREATE_MEDIA_ITEM, { mediaItem, success })
export const connectCreateMediaItems = ({ mediaItems, success }: any) =>
  ac(CONNECT_CREATE_MEDIA_ITEMS, { mediaItems, success })
export const connectUpdateMediaItemById = ({ mediaItemId, mediaItem }: any) =>
  ac(CONNECT_UPDATE_MEDIA_ITEM_BY_ID, { mediaItemId, mediaItem })
export const connectRemoveMediaItemById = ({ mediaItemId, success }: any) =>
  ac(CONNECT_REMOVE_MEDIA_ITEM_BY_ID, { mediaItemId, success })
export const connectMediaImageUpload = ({ prefix, images, success }: any) =>
  ac(CONNECT_MEDIA_IMAGE_UPLOAD, { prefix, images, success })
