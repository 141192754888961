import { ac } from '../actions'
import { IBenstailorUser } from '../../components/types'

export const FETCH_BENSTAILOR_USERS = 'FETCH_BENSTAILOR_USERS'
export const FETCH_BENSTAILOR_USERS_SUCCESS = 'FETCH_BENSTAILOR_USERS_SUCCESS'
export const FETCH_BENSTAILOR_USERS_FAILED = 'FETCH_BENSTAILOR_USERS_FAILED'
export const CREATE_BENSTAILOR_USER = 'CREATE_BENSTAILOR_USER'
export const CREATE_BENSTAILOR_USER_SUCCESS = 'CREATE_BENSTAILOR_USER_SUCCESS'
export const CREATE_BENSTAILOR_USER_FAILED = 'CREATE_BENSTAILOR_USER_FAILED'
export const REMOVE_BENSTAILOR_USER_BY_ID = 'REMOVE_BENSTAILOR_USER_BY_ID'
export const REMOVE_BENSTAILOR_USER_BY_ID_SUCCESS =
  'REMOVE_BENSTAILOR_USER_BY_ID_SUCCESS'
export const REMOVE_BENSTAILOR_USER_BY_ID_FAILED =
  'REMOVE_BENSTAILOR_USER_BY_ID_FAILED'
export const VERIFY_BENSTAILOR_USER_BY_ID = 'VERIFY_BENSTAILOR_USER_BY_ID'
export const VERIFY_BENSTAILOR_USER_BY_ID_SUCCESS =
  'VERIFY_BENSTAILOR_USER_BY_ID_SUCCESS'
export const VERIFY_BENSTAILOR_USER_BY_ID_FAILED =
  'VERIFY_BENSTAILOR_USER_BY_ID_FAILED'

export const fetchBenstailorUsers = () => ac(FETCH_BENSTAILOR_USERS)
export const fetchBenstailorUsersSuccess = (payload: {}) =>
  ac(FETCH_BENSTAILOR_USERS_SUCCESS, payload)
export const fetchBenstailorUsersFailed = (ex: {}) =>
  ac(FETCH_BENSTAILOR_USERS_SUCCESS, ex)

export const createBenstailorUser = (
  user: IBenstailorUser,
  onSuccess?: () => void
) => ac(CREATE_BENSTAILOR_USER, { user, onSuccess })

export const removeBenstailorUserById = (userId: string) =>
  ac(REMOVE_BENSTAILOR_USER_BY_ID, { userId })

export const verifyBenstailorUserById = (userId: string) =>
  ac(VERIFY_BENSTAILOR_USER_BY_ID, { userId })
