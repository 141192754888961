import { CONFIG } from '../../config'
import { RequestHelper } from './RequestHelper'
import { AuthHelper } from '../AuthHelper'

const LAPUTAN_SCHOOL_ADMIN_API_URL = `${CONFIG.LAPUTAN_API_URL}/schools/admin`

class SchoolAdminRequestHelper {
  public static async request(query: object, variables?: object) {
    const headers = await AuthHelper.getAuthHeader()
    return RequestHelper.graphQLRequest(
      LAPUTAN_SCHOOL_ADMIN_API_URL,
      query,
      variables,
      headers
    )
  }
}

export { SchoolAdminRequestHelper }
