import {
  FETCH_SCHOOL_LISTING_CATEGORIES,
  FETCH_SCHOOL_LISTING_CATEGORIES_FAILED,
  FETCH_SCHOOL_LISTING_CATEGORIES_SUCCESS,
} from './actions'
import { sort, ascend, prop } from '../../../lib/ramda'

export interface SchoolListingCatgoriesState {
  isFetching: boolean
  list: Array<any>
}

const initialState = {
  isFetching: false,
  list: [],
}

const schoolListingCategories = (
  state: any = initialState,
  { type, payload }: any
): SchoolListingCatgoriesState => {
  switch (type) {
    case FETCH_SCHOOL_LISTING_CATEGORIES: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case FETCH_SCHOOL_LISTING_CATEGORIES_SUCCESS: {
      const { categories } = payload.getSchoolListingCategories
      const list = sort(ascend(prop('name')), categories)
      return {
        ...state,
        isFetching: false,
        list,
      }
    }
    case FETCH_SCHOOL_LISTING_CATEGORIES_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export { schoolListingCategories, initialState }
