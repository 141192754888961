import { ac } from '../actions'
import { School } from '../../containers/props/School.types'
import { PaymentAction } from './types'

export const createBasicSchoolPlanPaymentIntentAction = (
  schoolId: string,
  complete?: () => void
) =>
  ac(PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT, {
    schoolId,
    complete,
  })

export const checkHasBasicManageAccessAction = (
  schoolId: string,
  success?: ({ hasAccess }: { hasAccess: boolean }) => void
) =>
  ac(PaymentAction.CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS, {
    schoolId,
    success,
  })

export const getSchoolPlanFeesAction = () =>
  ac(PaymentAction.GET_SCHOOL_PLAN_FEES)

export const showEditSchoolModal = (school: School) =>
  ac(PaymentAction.SHOW_EDIT_SCHOOL_MODAL, { school })

export const getPaidInvoices = () => ac(PaymentAction.GET_PAID_INVOICES)
