import { modals, ModalState } from '../states/modals/reducer'
import { alerts, AlertState } from '../states/alerts/reducer'
import { loadingBar, LoadingBarState } from '../states/loadingBar/reducer'
import { payments, PaymentsState } from '../states/payments/reducer'
import { scrolling, ScrollingState } from '../states/scrolling/reducer'
import { schools, SchoolsState } from '../states/schools/reducer'
import {
  schoolListingCategories,
  SchoolListingCatgoriesState,
} from '../states/schools/listingCategories/reducer'
import {
  schoolsAutoSuggest,
  SchoolsAutoSuggestState,
} from '../states/schools/autoSuggest/reducer'
import { screenSize, ScreenSizeState } from '../states/screenSize/reducer'
import { schoolPosts, SchoolPostsState } from '../states/schools/posts/reducer'
import { currentUser, CurrentUserState } from '../states/currentUser/reducer'
import {
  connectMedia,
  ConnectMediaState,
} from '../states/connect/media/reducer'
import {
  schoolSuburbs,
  SchoolSuburbsState,
} from '../states/schools/suburbs/reducer'
import {
  popularSuburbs,
  PopularSuburbsState,
} from '../states/schools/popularSuburbs/reducer'
import { panels, PanelState } from '../states/panels/reducer'

interface RootState {
  modals: ModalState
  alerts: AlertState
  loadingBar: LoadingBarState
  scrolling: ScrollingState
  screenSize: ScreenSizeState
  connectMedia: ConnectMediaState
  currentUser: CurrentUserState
  payments: PaymentsState
  schools: SchoolsState
  schoolPosts: SchoolPostsState
  schoolsAutoSuggest: SchoolsAutoSuggestState
  schoolListingCategories: SchoolListingCatgoriesState
  schoolSuburbs: SchoolSuburbsState
  popularSuburbs: PopularSuburbsState
  panels: PanelState
}

export const reducers = {
  modals,
  alerts,
  loadingBar,
  scrolling,
  screenSize,
  connectMedia,
  currentUser,
  payments,
  schools,
  schoolPosts,
  schoolsAutoSuggest,
  schoolListingCategories,
  schoolSuburbs,
  popularSuburbs,
  panels,
}

export const persistentStores = ['currentUser', 'schools']

export const overrideState = {}

export const preloadedState = {
  schools: {
    isSSR: true,
    list: [],
  },
}
