import {
  FETCH_SCHOOL_POST_BY_ID,
  FETCH_SCHOOL_POST_BY_ID_FAILED,
  FETCH_SCHOOL_POST_BY_ID_SUCCESS,
  FETCH_SCHOOL_POSTS,
  FETCH_SCHOOL_POSTS_FAILED,
  FETCH_SCHOOL_POSTS_SUCCESS,
} from './actions'
import { sort, descend, prop } from '../../../lib/ramda'

export interface SchoolPostsState {
  isFetching: boolean
  list?: Array<any>
}

const initialState = {
  isFetching: false,
  list: [],
}

const schoolPosts = (
  state: any = initialState,
  { type, payload }: any
): SchoolPostsState => {
  switch (type) {
    case FETCH_SCHOOL_POSTS: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case FETCH_SCHOOL_POSTS_SUCCESS: {
      const { posts } = payload.getSchoolPosts
      const byPostedAt = descend(prop('posted_at'))
      const list = sort(byPostedAt, posts)
      return {
        ...state,
        isFetching: false,
        list,
      }
    }
    case FETCH_SCHOOL_POSTS_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    case FETCH_SCHOOL_POST_BY_ID: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case FETCH_SCHOOL_POST_BY_ID_SUCCESS: {
      const { post } = payload.getSchoolPostById
      const posts = state.list.filter((p: any) => p.id !== post.id).concat(post)
      const byPostedAt = descend(prop('posted_at'))
      return {
        ...state,
        isFetching: false,
        list: sort(byPostedAt, posts),
      }
    }
    case FETCH_SCHOOL_POST_BY_ID_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export { schoolPosts, initialState }
