import { takeLatest, select } from 'redux-saga/effects'
import { SchoolListingGraphql } from '@laputan/graphql/dist/SchoolListings.graphql'
import { GET_SCHOOL_LISTINGS_BY_SCHOOL_ID } from './actions'
import { SchoolSagaRequestHelper } from '../../../helpers/SagaRequestHelper/SchoolSagaRequestHelper'

export function* performGetSchoolListingsBySchoolId(action: any) {
  const {
    payload: { schoolId },
  } = action
  // @ts-ignore
  const school = yield select(({ schools }) =>
    schools.list.find((s: any) => s.id === schoolId)
  )
  yield SchoolSagaRequestHelper.request({
    action: {
      type: action.type,
      payload: {
        coords: school.location.coords,
      },
      callbackParams: {
        schoolId,
      },
    },
    graphql: SchoolListingGraphql.GetSchoolListingsByCoords,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(
    GET_SCHOOL_LISTINGS_BY_SCHOOL_ID,
    performGetSchoolListingsBySchoolId
  )
}

export default [watchers()]
