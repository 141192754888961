import { all } from 'redux-saga/effects'
import paymentSagas from '../states/payments/sagas'
import schoolsSagas from '../states/schools/sagas'
import schoolsAutoSuggestSagas from '../states/schools/autoSuggest/sagas'
import schoolListingsSagas from '../states/schools/listings/sagas'
import schoolListingCategoriesSagas from '../states/schools/listingCategories/sagas'
import schoolChangeRequestSagas from '../states/schools/changeRequests/sagas'
import schoolPostSagas from '../states/schools/posts/sagas'
import subscriptionSagas from '../states/subscriptions/sagas'
import currentUserSagas from '../states/currentUser/sagas'
import enquirySagas from '../states/enquiries/sagas'
import schoolSuburbSagas from '../states/schools/suburbs/sagas'
import popularSuburbSagas from '../states/schools/popularSuburbs/sagas'

export default function* rootSaga() {
  yield all([
    ...currentUserSagas,
    ...paymentSagas,
    ...enquirySagas,
    ...schoolsSagas,
    ...schoolPostSagas,
    ...schoolsAutoSuggestSagas,
    ...schoolListingsSagas,
    ...schoolListingCategoriesSagas,
    ...schoolChangeRequestSagas,
    ...subscriptionSagas,
    ...schoolSuburbSagas,
    ...popularSuburbSagas,
  ])
}
