import { takeLatest } from 'redux-saga/effects'
import { SchoolListingGraphql } from '@laputan/graphql/dist/SchoolListings.graphql'
import { FETCH_SCHOOL_LISTING_CATEGORIES } from './actions'
import { SchoolSagaRequestHelper } from '../../../helpers/SagaRequestHelper/SchoolSagaRequestHelper'

export function* performFetchListingCategories(action: any) {
  yield SchoolSagaRequestHelper.request({
    action,
    graphql: SchoolListingGraphql.GetSchoolListingCategories,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(
    FETCH_SCHOOL_LISTING_CATEGORIES,
    performFetchListingCategories
  )
}

export default [watchers()]
