import { takeLatest, takeLeading, put } from 'redux-saga/effects'
import { SchoolsGraphql } from '@laputan/graphql/dist/Schools.graphql'
import { SchoolAdminGraphql } from '@laputan/graphql/dist/SchoolAdmin.graphql'
import {
  GET_SCHOOL_BY_ID,
  GET_SCHOOL_BY_ID_FAILED,
  GET_SCHOOL_BY_ID_SUCCESS,
  GET_TOP_SCHOOLS_IN_STATES,
  GET_TOP_SUBURBS_IN_STATES,
  SEARCH_SCHOOLS,
  SEARCH_SCHOOLS_FAILED,
  SEARCH_SCHOOLS_SUCCESS,
  SHOW_SCHOOL_LOGIN_MODAL,
  UPDATE_SCHOOL_FOR_BASIC_PLAN_USER,
  UPLOAD_SCHOOL_LOGO,
  VERIFY_SCHOOL_REQUEST,
} from './actions'
import { ac } from '../actions'
import { SchoolAdminRequestHelper } from '../../helpers/RequestHelper/SchoolAdminRequestHelper'
import { SchoolRequestHelper } from '../../helpers/RequestHelper/SchoolRequestHelper'
import { getSchoolListingsBySchoolId } from './listings/actions'
import { SchoolSagaRequestHelper } from '../../helpers/SagaRequestHelper/SchoolSagaRequestHelper'
import { showModal } from '../modals/actions'
import { ModalType } from '../modals/ModalType.constants'
import { SchoolAdminSagaRequestHelper } from '../../helpers/SagaRequestHelper/SchoolAdminSagaRequestHelper'
import { UploadImageSagaHelper } from '../../helpers/UploadImageSagaHelper'
import { IEditSchoolFormFields } from '../../types/SchoolAdmin.types'
export function* performSearchSchools(action: any) {
  const {
    payload: { page, state, search, sortBy, coords, levels, sector, gender },
  } = action || { payload: {} }
  const { data } = yield SchoolRequestHelper.request(
    SchoolsGraphql.SchoolSearch,
    {
      page: page || 1,
      state,
      search,
      sortBy: sortBy || 'name',
      coords,
      levels,
      sector,
      gender,
    }
  )

  if (data) {
    const {
      searchSchools: { schools, count, currentPage },
    } = data
    yield put(ac(SEARCH_SCHOOLS_SUCCESS, { schools, count, currentPage }))
  } else {
    yield put(ac(SEARCH_SCHOOLS_FAILED, {}))
  }
}

function* performGetSchoolById(action: any) {
  try {
    const id: string = action.payload.id as string
    const { data } = yield SchoolRequestHelper.request(
      SchoolsGraphql.GetSchoolsById,
      { id }
    )
    const {
      getSchoolById: { school },
    } = data
    yield put(ac(GET_SCHOOL_BY_ID_SUCCESS, { school }))
    yield put(
      getSchoolListingsBySchoolId({
        schoolId: school.id,
      })
    )
  } catch (ex) {
    console.log('performGetSchoolById ex', ex)
    yield put(ac(GET_SCHOOL_BY_ID_FAILED, { ex }))
  }
}

function* performGetTopSchoolsInStates(action: any) {
  yield SchoolSagaRequestHelper.request({
    action,
    graphql: SchoolsGraphql.GetTopSchoolsInStates,
  })
}

function* performGetTopSuburbsInStates(action: any) {
  yield SchoolSagaRequestHelper.request({
    action,
    graphql: SchoolsGraphql.GetTopSuburbsInStates,
  })
}

function* performShowSchoolLoginModal() {
  yield put(
    showModal(ModalType.LOGIN, {
      props: {
        hasSignUp: true,
        isAuthByCognito: true,
        isAuthByFacebook: true,
        isAuthByGoogle: true,
      },
    })
  )
}

// { type: 'UPDATE_SCHOOL_FOR_BASIC_PLAN_USER', payload: { schoolId, schoolData } }
function* performUpdateSchoolForBasicPlanUser(action: {
  type: string
  payload: {
    schoolId: string
    schoolData: IEditSchoolFormFields
    success: () => void
    complete: () => void
  }
}) {
  yield SchoolAdminSagaRequestHelper.request({
    action,
    graphql: SchoolAdminGraphql.UpdateSchoolForBasicPlanUser,
  })
}

function* performUploadSchoolLogo(action: {
  type: string
  payload: {
    file: File
    contentType: string
    success: (options: { preSignedUrl: string; urlPath: string }) => void
  }
}) {
  yield UploadImageSagaHelper.performSchoolLogoUpload({
    action,
    requester: SchoolAdminRequestHelper.request,
    graphql: SchoolAdminGraphql.SchoolAdminGetMediaPreSignUrl,
  })
}

function* performVerifySchoolRequest(action: {
  type: string
  payload: { schoolId: string; success: () => void }
}) {
  yield SchoolAdminSagaRequestHelper.request({
    action,
    graphql: SchoolAdminGraphql.VerifySchoolRequest,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(SEARCH_SCHOOLS, performSearchSchools)
  yield takeLatest(GET_SCHOOL_BY_ID, performGetSchoolById)
  yield takeLatest(GET_TOP_SCHOOLS_IN_STATES, performGetTopSchoolsInStates)
  yield takeLatest(GET_TOP_SUBURBS_IN_STATES, performGetTopSuburbsInStates)
  yield takeLatest(SHOW_SCHOOL_LOGIN_MODAL, performShowSchoolLoginModal)
  yield takeLatest(
    UPDATE_SCHOOL_FOR_BASIC_PLAN_USER,
    performUpdateSchoolForBasicPlanUser
  )
  yield takeLatest(UPLOAD_SCHOOL_LOGO, performUploadSchoolLogo)
  yield takeLeading(VERIFY_SCHOOL_REQUEST, performVerifySchoolRequest)
}

export default [watchers()]
