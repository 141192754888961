import { SHOW_LOADING_BAR, HIDE_LOADING_BAR } from './actions'
import { mergeRight } from '../../lib/ramda'

export interface LoadingBarState {
  isLoading: boolean
}

const initialState = {
  isLoading: false,
}

const loadingBar = (
  state: any = initialState,
  { type }: any
): LoadingBarState => {
  switch (type) {
    case SHOW_LOADING_BAR:
      return mergeRight(state, { isLoading: true })
    case HIDE_LOADING_BAR:
      return mergeRight(state, { isLoading: false })
    default:
      return state
  }
}

export { loadingBar }
