import { BREAKPOINT, SCREEN_SIZE } from '../static/style'
import { orderFromList, reverse } from '../lib/ramda'
import { ScreenSize } from '../states/screenSize/types'

const SCREEN_SIZES = Object.keys(SCREEN_SIZE)

const ScreenSizeHelper = {
  getBreakPoint: (clientWidth: number) => {
    for (let i = 0; i < SCREEN_SIZES.length; i++) {
      const screenSize: string = SCREEN_SIZES[i]
      // @ts-ignore
      const breakPoint: number = BREAKPOINT[screenSize]
      if (breakPoint > clientWidth) {
        return SCREEN_SIZES[i - 1]
      }
    }
    return SCREEN_SIZES[SCREEN_SIZES.length - 1]
  },

  isBreakpointGreaterAndEqual: (
    currentBreakpoint: ScreenSize,
    minBreakpoint: ScreenSize
  ): boolean => {
    if (minBreakpoint === 'default') {
      return
    }
    const minBreakpointIndex = SCREEN_SIZES.indexOf(minBreakpoint)
    const currentBreakpointIndex = SCREEN_SIZES.indexOf(currentBreakpoint)
    return currentBreakpointIndex >= minBreakpointIndex
  },

  getScreenSizeValueFromObject: (
    currentBreakpoint: any,
    screenValueMap: any
  ) => {
    const value = screenValueMap[currentBreakpoint]
    if (value) {
      return value
    }
    const availableScreenSizes = Object.keys(screenValueMap)
    const reverseScreenSizes = reverse(SCREEN_SIZES)
    const orderedAvailableScreenSizes = orderFromList(
      reverseScreenSizes,
      availableScreenSizes
    )
    for (let i = 0; i < orderedAvailableScreenSizes.length; i++) {
      const screenSize = orderedAvailableScreenSizes[i]
      if (
        ScreenSizeHelper.isBreakpointGreaterAndEqual(
          currentBreakpoint,
          screenSize
        )
      ) {
        return screenValueMap[screenSize]
      }
    }
    return screenValueMap.default
  },
}

export { ScreenSizeHelper }
