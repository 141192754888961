module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-apollo@4.0.3_@apollo+client@3.10.8_@types+react@18.0.28_graphql@16.9.0_react-do_sq4bnllo3qmtqrgiqyjl7j37py/node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.25._muzv4fz4vn6umsehoud7cvya6e/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_@types+webpack@4.41.39_babel-eslint@10.1.0_eslint_3acazebp2cig6hpzcgmp23qlyy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AroundSchools","start_url":"/","display":"minimal-ui","icon":"src/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e938680ffb1062b11936b0b9f5250e10"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.6_@types+webpack@4.41.39_babel-eslint@10.1_prnmfh7hnt5g6zvavs2thetkn4/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NSV6N8V","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.6_@types+webpack@4.41.39_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react_uktac4rmetq7cds67k6pdhxfda/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
