import { IInvoice } from '@laputan/core/dist/types/Invoice.types'
export enum PaymentAction {
  CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT = 'CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT',
  CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_SUCCESS = 'CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_SUCCESS',
  CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_FAILED = 'CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_FAILED',
  GET_SCHOOL_PLAN_FEES = 'GET_SCHOOL_PLAN_FEES',
  GET_SCHOOL_PLAN_FEES_SUCCESS = 'GET_SCHOOL_PLAN_FEES_SUCCESS',
  GET_SCHOOL_PLAN_FEES_FAILED = 'GET_SCHOOL_PLAN_FEES_FAILED',
  CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS = 'CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS',
  CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS_SUCCESS = 'CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS_SUCCESS',
  CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS_FAILED = 'CHECK_HAS_SCHOOL_BASIC_MANAGE_ACCESS_FAILED',
  SHOW_EDIT_SCHOOL_MODAL = 'SHOW_EDIT_SCHOOL_MODAL',
  GET_PAID_INVOICES = 'GET_PAID_INVOICES',
  GET_PAID_INVOICES_SUCCESS = 'GET_PAID_INVOICES_SUCCESS',
  GET_PAID_INVOICES_FAILED = 'GET_PAID_INVOICES_FAILED',
}

export type CreatePaymentIntentSuccessActionWithPayload = {
  type: PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_SUCCESS
  payload: {
    clientSecret: string
  }
}

export type GetSchoolPlanFeesSuccessActionWithPayload = {
  type: PaymentAction.GET_SCHOOL_PLAN_FEES_SUCCESS
  payload: {
    fees: {
      BASIC_SCHOOL_PLAN: string
    }
  }
}

export type PaymentActionNoPayload = {
  type:
    | PaymentAction.GET_PAID_INVOICES
    | PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT
    | PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_FAILED
    | PaymentAction.GET_PAID_INVOICES_FAILED
  payload: {}
}

export type GetPaidInvoicesWithPayload = {
  type: PaymentAction.GET_PAID_INVOICES_SUCCESS
  payload: {
    invoices: IInvoice[]
  }
}

export type PaymentActionWithPayload =
  | CreatePaymentIntentSuccessActionWithPayload
  | GetSchoolPlanFeesSuccessActionWithPayload
  | GetPaidInvoicesWithPayload
