import { HIDE_FLOATING_AUTH_PANEL, SHOW_FLOATING_AUTH_PANEL } from './actions'

export type PanelState = {
  showFloatingAuthPanel: boolean
}

const initialState = {
  showFloatingAuthPanel: false,
}

const panels = (
  state = initialState,
  { type }: { type: string; payload: any }
): PanelState => {
  switch (type) {
    case SHOW_FLOATING_AUTH_PANEL:
      return {
        ...state,
        showFloatingAuthPanel: true,
      }
    case HIDE_FLOATING_AUTH_PANEL:
      return {
        ...state,
        showFloatingAuthPanel: false,
      }
    default:
      return state
  }
}

export { panels }
