import {
  SHOW_ALERT,
  SHOW_URGENT_ALERT,
  SHOW_ERROR_ALERT,
  SHOW_SUCCESS_ALERT,
  HIDE_ALERT,
  HIDE_ALERTS,
  SHOW_WARNING_ALERT,
  SHOW_INFO_ALERT,
} from './actions'
import { take } from '../../lib/ramda'
import { AlertType } from '../../components/Alert/Alert.types'

const MAX_MESSAGES = 1

const generateAlertId = (text: string) => `${text}-${new Date().getTime()}`

export interface AlertState {
  list: Array<any>
}

const initialState = {
  list: [],
}

const alerts = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
): AlertState => {
  const { messageText, id } = payload || {}
  switch (type) {
    case SHOW_SUCCESS_ALERT:
    case SHOW_ALERT:
    case SHOW_ERROR_ALERT:
    case SHOW_INFO_ALERT:
    case SHOW_URGENT_ALERT:
    case SHOW_WARNING_ALERT: {
      const newMessages: any = [
        {
          id: generateAlertId(messageText),
          type: {
            SHOW_SUCCESS_ALERT: AlertType.SUCCESS,
            SHOW_WARNING_ALERT: AlertType.WARNING,
            SHOW_ALERT: AlertType.ERROR,
            SHOW_INFO_ALERT: AlertType.INFO,
            SHOW_URGENT_ALERT: AlertType.URGENT,
            SHOW_ERROR_ALERT: AlertType.ERROR,
          }[type],
          message: messageText,
        },
      ].concat(state.list || [])
      return {
        list: take(MAX_MESSAGES, newMessages),
      }
    }
    case HIDE_ALERT:
      return { list: state.list.filter(({ id: currId }) => id !== currId) }
    case HIDE_ALERTS:
      return initialState
    default:
      return state
  }
}

export { alerts }
