import {
  GET_CURRENT_USER_INFO,
  GET_CURRENT_USER_INFO_FAILED,
  GET_CURRENT_USER_INFO_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_CURRENT_USER_PROFILE,
  UPDATE_CURRENT_USER_PROFILE_FAILED,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  UPDATE_LOCAL_USER_PROFILE,
} from './actions'
import { mergeRight, mergeDeepRight } from '../../lib/ramda'
import { FETCH_BENSTAILOR_USERS_SUCCESS } from '../benstailorUsers/actions'

export interface CurrentUserState {
  isFetching: boolean
  isUpdating: boolean
  isLoggingIn: boolean
  userInfo: any
}

const initialState = {
  isFetching: false,
  isUpdating: false,
  isLoggingIn: false,
  userInfo: null,
}

const currentUser = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case LOGIN: {
      return mergeDeepRight(state, {
        isLoggingIn: true,
      })
    }
    case LOGIN_FAILED:
    case LOGIN_SUCCESS: {
      return mergeDeepRight(state, {
        ...payload,
        isLoggingIn: false,
      })
    }
    case LOGOUT:
      return initialState
    case FETCH_BENSTAILOR_USERS_SUCCESS:
      const user = payload.users.find(
        (u: any) => u.email === state.userInfo.email
      )
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          name: user?.name,
          verified: user?.verified,
          role: user?.role,
          userId: user?.id,
        },
      }
    case GET_CURRENT_USER_INFO:
      return mergeRight(state, {
        isFetching: true,
      })
    case GET_CURRENT_USER_INFO_SUCCESS:
      return mergeDeepRight(state, {
        isFetching: false,
        userInfo: payload.user || {},
      })
    case GET_CURRENT_USER_INFO_FAILED:
      return mergeRight(state, {
        isFetching: false,
      })
    case UPDATE_CURRENT_USER_PROFILE:
      return mergeRight(state, {
        isUpdating: true,
      })
    case UPDATE_CURRENT_USER_PROFILE_SUCCESS:
      return mergeDeepRight(state, {
        isUpdating: false,
        userInfo: payload.user || {},
      })
    case UPDATE_CURRENT_USER_PROFILE_FAILED:
      return mergeRight(state, {
        isUpdating: false,
      })
    case UPDATE_LOCAL_USER_PROFILE: {
      return mergeRight(state, {
        userInfo: {
          email: payload.email,
          jwtToken: payload.jwtToken,
          refreshToken: payload.refreshToken,
        },
      })
    }
    default:
      return state
  }
}

export { currentUser, initialState }
