import { takeLatest, put } from 'redux-saga/effects'
import { ac } from '../../actions'
import { hideLoadingBar } from '../../loadingBar/actions'
import {
  SCHOOL_AUTO_SUGGEST_SEARCH,
  SCHOOL_AUTO_SUGGEST_SEARCH_FAILED,
  SCHOOL_AUTO_SUGGEST_SEARCH_SUCCESS,
} from './actions'
import { pathOr } from '../../../lib/ramda'
import { SchoolRequestHelper } from '../../../helpers/RequestHelper/SchoolRequestHelper'
import { STATE_TO_TEXT } from './autoSuggestStateData'

function* performSchoolAutoSuggestSearch(action: any) {
  try {
    const {
      payload: { query },
    } = action || { payload: {} }
    let data
    const trimmedQuery = query.trim()
    if (trimmedQuery.length >= 3) {
      // @ts-ignore
      const resp = yield SchoolRequestHelper.request(
        `
        query AutoSuggestSearch($query: String!) {
          autoSuggestSearch(query: $query) {
            localities {
              suburb
              state
              postcode
              coords
            }
            schools {
              id
              name
              logo
              location {
                state
                suburb
              }
              results
              gender
              sector
              level
            }
          }
        }
      `,
        { query }
      )
      // eslint-disable-next-line prefer-destructuring
      data = resp.data
    }

    const states = STATE_TO_TEXT.filter(
      ({ displayText, value }) =>
        new RegExp(trimmedQuery, 'i').test(displayText) ||
        new RegExp(trimmedQuery, 'i').test(value)
    )

    if (data || states.length > 0) {
      yield put(
        ac(SCHOOL_AUTO_SUGGEST_SEARCH_SUCCESS, {
          schools: pathOr([], ['autoSuggestSearch', 'schools'], data),
          localities: pathOr([], ['autoSuggestSearch', 'localities'], data),
          states,
        })
      )
    } else {
      yield put(ac(SCHOOL_AUTO_SUGGEST_SEARCH_FAILED, {}))
    }
    yield put(hideLoadingBar())
  } catch (ex) {
    console.log('performSchoolAutoSuggestSearch ex', ex)
    yield put(hideLoadingBar())
  }
}

function* watchSchoolAutoSuggestSearch() {
  yield takeLatest(SCHOOL_AUTO_SUGGEST_SEARCH, performSchoolAutoSuggestSearch)
}

export default [watchSchoolAutoSuggestSearch()]
