import { SagaRequestHelper } from './SagaRequestHelper'
import { SchoolRequestHelper } from '../RequestHelper/SchoolRequestHelper'

const SchoolSagaRequestHelper = {
  request: function*(props: any) {
    yield SagaRequestHelper.request({
      requester: SchoolRequestHelper.request,
      ...props,
    })
  },
}

export { SchoolSagaRequestHelper }
