import { ENABLE_SCORLLING, DISABLE_SCRROLLING } from './actions'

export interface ScrollingState {
  canScroll: boolean
}

const initialState = {
  canScroll: true,
}

const scrolling = (
  state: any = initialState,
  { type }: any
): ScrollingState => {
  switch (type) {
    case ENABLE_SCORLLING:
      return { canScroll: true }
    case DISABLE_SCRROLLING:
      return { canScroll: false }
    default:
      return state
  }
}

export { scrolling }
