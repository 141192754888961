import { ac } from '../../actions'

export const GET_SUBURBS_BY_STATE = 'GET_SUBURBS_BY_STATE'
export const GET_SUBURBS_BY_STATE_SUCCESS = 'GET_SUBURBS_BY_STATE_SUCCESS'
export const GET_SUBURBS_BY_STATE_FAILED = 'GET_SUBURBS_BY_STATE_FAILED'

export const getSuburbByState = ({
  state,
  startWith,
}: {
  state: string
  startWith?: string
}) =>
  ac(GET_SUBURBS_BY_STATE, {
    state,
    startWith,
  })
