import {
  GET_SUBURBS_BY_STATE,
  GET_SUBURBS_BY_STATE_FAILED,
  GET_SUBURBS_BY_STATE_SUCCESS,
} from './actions'

type Suburb = {
  suburb: string
  state: string
  id: string
  postcode: string
}

export interface SchoolSuburbsState {
  isFetching: boolean
  list?: Array<Suburb>
}

const initialState = {
  isFetching: false,
  list: [],
}

const schoolSuburbs = (
  state: any = initialState,
  { type, payload }: any
): SchoolSuburbsState => {
  switch (type) {
    case GET_SUBURBS_BY_STATE: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case GET_SUBURBS_BY_STATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: payload.suburbs,
      }
    }
    case GET_SUBURBS_BY_STATE_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export { schoolSuburbs, initialState }
