import { CONFIG } from '../../config'
import { RequestHelper } from './RequestHelper'

const LAPUTAN_CHANGE_REQUESTS_API_URL = `${CONFIG.LAPUTAN_API_URL}/changeRequests`

const ChangeRequestsRequestHelper = {
  request: (query: any, variables: any) =>
    RequestHelper.graphQLRequest(
      LAPUTAN_CHANGE_REQUESTS_API_URL,
      query,
      variables
    ),
}

export { ChangeRequestsRequestHelper }
