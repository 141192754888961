import { ac } from '../actions'
import { DataSalesEnquiry } from '@laputan/core/dist/types/Enquiry.types'

export const SUBMIT_ENQUIRY = 'SUBMIT_ENQUIRY'
export const SUBMIT_ENQUIRY_SUCCESS = 'SUBMIT_ENQUIRY_SUCCESS'
export const SUBMIT_ENQUIRY_FAILED = 'SUBMIT_ENQUIRY_FAILED'
export const SUBMIT_DATA_SALES_ENQUIRY = 'SUBMIT_DATA_SALES_ENQUIRY'
export const SUBMIT_DATA_SALES_ENQUIRY_SUCCESS =
  'SUBMIT_DATA_SALES_ENQUIRY_SUCCESS'
export const SUBMIT_DATA_SALES_ENQUIRY_FAILED =
  'SUBMIT_DATA_SALES_ENQUIRY_FAILED'

export const submitEnquiry = ({ enquiry }: any) =>
  ac(SUBMIT_ENQUIRY, { enquiry })
export const submitDataSalesEnquiry = ({
  enquiry,
  onSuccess,
  onFailed,
}: {
  enquiry: DataSalesEnquiry
  onSuccess: () => void
  onFailed: () => void
}) => ac(SUBMIT_DATA_SALES_ENQUIRY, { enquiry, onSuccess, onFailed })
