import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { createHttpLink } from 'apollo-link-http'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'

import { RequestHelper } from './RequestHelper'
import { CONFIG } from '../../config'
import { AuthHelper } from '../AuthHelper'

const LAPUTAN_SCHOOLS_API_URL = `${CONFIG.LAPUTAN_API_URL}/schools`
const LAPUTAN_SCHOOLS_AUTH_API_URL = `${CONFIG.LAPUTAN_API_URL}/schools/auth`

const SchoolRequest = new ApolloClient({
  // @ts-ignore
  link: createPersistedQueryLink({ useGETForHashedQueries: true }).concat(
    createHttpLink({
      uri: LAPUTAN_SCHOOLS_API_URL,
    })
  ),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

const SchoolRequestHelper = {
  request: async (query: any, variables?: any) => {
    console.log(
      'ENABLE_APOLLO_AUTOMATIC_PERSISTED_QUERIES',
      CONFIG.ENABLE_APOLLO_AUTOMATIC_PERSISTED_QUERIES
    )
    console.log('AuthHelper.isAuthenticated()', AuthHelper.isAuthenticated())
    // without auth
    if (!AuthHelper.isAuthenticated()) {
      return SchoolRequest.query({
        query: gql`
          ${query}
        `,
        variables,
      })
    }
    const headers = await AuthHelper.getAuthHeader()
    return RequestHelper.graphQLRequest(
      LAPUTAN_SCHOOLS_AUTH_API_URL,
      query,
      variables,
      headers
    ).then((data) => ({ data }))
  },
}

export { SchoolRequestHelper }
