import { put } from 'redux-saga/effects'
import { ac } from '../states/actions'

const UploadImageSagaHelper = {
  performSchoolLogoUpload: function* ({
    action,
    requester,
    graphql,
    shouldTriggerSuccessEvent = true,
  }: {
    action: {
      type: string
      payload: {
        file: File
        contentType: string
        success: (options: { preSignedUrl: string; urlPath: string }) => void
      }
    }
    requester: any
    graphql: any
    shouldTriggerSuccessEvent?: boolean
  }) {
    const {
      payload: { file, contentType, success },
      type,
    } = action
    console.log(file, contentType)
    try {
      const res: {
        schoolAdminGetMediaPreSignUrl: {
          preSignedUrl: string
          urlPath: string
        }
      } = yield requester(graphql, { contentType })
      const { preSignedUrl, urlPath } = res.schoolAdminGetMediaPreSignUrl

      yield fetch(preSignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      })
      if (shouldTriggerSuccessEvent) {
        yield put(ac(`${type}_SUCCESS`))
      }
      if (success) {
        success({
          preSignedUrl,
          urlPath,
        })
      }
    } catch (err: any) {
      const { code, name, message } = err
      console.log('Error while performUploadImage', code, name, message)
      yield put(ac(`${type}_FAILED`, { code, name, message }))
    }
  },

  performUploadImage: function* ({
    action,
    requester,
    graphql,
    requestParams = {},
    shouldTriggerSuccessEvent = true,
  }: any): any {
    const {
      payload: { images, success },
      type,
    } = action
    try {
      const mediaItems: Array<any> = []
      yield Promise.all(
        images.map(async ({ file, contentType }: any) => {
          const resp = await requester(graphql, {
            contentType,
            ...requestParams,
          })

          const keys = Object.keys(resp)
          const key = keys[0]
          const { preSignedUrl, urlPath } = resp[key]

          mediaItems.push({
            title: file.name,
            key: urlPath,
          })
          await fetch(preSignedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': contentType,
            },
            body: file,
          })
        })
      )

      if (success) {
        success({
          mediaItems,
        })
      }

      if (shouldTriggerSuccessEvent) {
        yield put(ac(`${type}_SUCCESS`))
      }
      return mediaItems
    } catch (ex: any) {
      const { code, name, message } = ex
      console.log(
        'Error while performConnectSchoolLogoUpload',
        code,
        name,
        message
      )
      yield put(ac(`${type}_FAILED`, { code, name, message }))
    }
  },
}

export { UploadImageSagaHelper }
