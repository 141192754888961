import { SagaRequestHelper, SagaRequestHelperParams } from './SagaRequestHelper'
import { SchoolAdminRequestHelper } from '../RequestHelper/SchoolAdminRequestHelper'

class SchoolAdminSagaRequestHelper {
  public static *request(props: SagaRequestHelperParams) {
    yield SagaRequestHelper.request({
      ...props,
      requester: SchoolAdminRequestHelper.request,
    })
  }
}

export { SchoolAdminSagaRequestHelper }
