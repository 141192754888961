import { ac } from '../../actions'

export const FETCH_SCHOOL_POSTS = 'FETCH_SCHOOL_POSTS'
export const FETCH_SCHOOL_POSTS_SUCCESS = 'FETCH_SCHOOL_POSTS_SUCCESS'
export const FETCH_SCHOOL_POSTS_FAILED = 'FETCH_SCHOOL_POSTS_FAILED'
export const FETCH_SCHOOL_POST_BY_ID = 'FETCH_SCHOOL_POST_BY_ID'
export const FETCH_SCHOOL_POST_BY_ID_SUCCESS = 'FETCH_SCHOOL_POST_BY_ID_SUCCESS'
export const FETCH_SCHOOL_POST_BY_ID_FAILED = 'FETCH_SCHOOL_POST_BY_ID_FAILED'

export const fetchSchoolPosts = () => ac(FETCH_SCHOOL_POSTS)
export const fetchSchoolPostById = ({ postId }: any) =>
  ac(FETCH_SCHOOL_POST_BY_ID, { postId })
