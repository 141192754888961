import { put } from 'redux-saga/effects'
import { ac } from '../../states/actions'

export type SagaRequestHelperParams = {
  action: {
    payload: any & {
      success?: (data: object) => void
      fail?: (ex: any) => void
      complete?: () => void
    }
    type: string
    callbackParams?: object
  }
  graphql: string
  success?: (data: object) => Promise<any>
  fail?: (ex: any) => Promise<any>
  requester?: (query: object, variables: object) => Promise<any>
}

const SagaRequestHelper = {
  request: function* ({
    action,
    graphql,
    success,
    fail,
    requester,
  }: SagaRequestHelperParams) {
    const { payload = {}, type: actionName, callbackParams } = action
    const {
      success: payloadSuccess,
      fail: payloadFail,
      complete: payloadComplete,
      ...requestPayload
    } = payload
    try {
      // @ts-ignore
      const resp: any = yield requester(graphql, requestPayload)
      const keys = Object.keys(resp)
      if (!keys) {
        console.log('performRequest: no keys in the response')
        return
      }
      const [key] = keys
      const data = resp[key]
      const newData = {
        ...data,
        callbackParams,
      }
      if (payloadSuccess) {
        payloadSuccess(newData)
      }
      if (success) {
        yield success(newData)
      }

      yield put(ac(`${actionName}_SUCCESS`, newData))
    } catch (ex) {
      console.log('exception request', ex)
      if (payloadFail) {
        payloadFail(ex)
      }
      if (fail) {
        yield fail(ex)
      }
      yield put(ac(`${actionName}_FAILED`, {}))
    }

    if (payloadComplete) {
      yield payloadComplete()
    }
  },
}

export { SagaRequestHelper }
