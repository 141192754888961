import { SchoolsGraphql } from '@laputan/graphql/dist/Schools.graphql'
import { takeLatest, put } from 'redux-saga/effects'
import { SchoolRequestHelper } from '../../../helpers/RequestHelper/SchoolRequestHelper'
import { ac } from '../../actions'
import {
  GET_SUBURBS_BY_STATE,
  GET_SUBURBS_BY_STATE_FAILED,
  GET_SUBURBS_BY_STATE_SUCCESS,
} from './actions'

function* performGetSuburbsByState(action: {
  type: string
  payload: { state: string; startWith: string }
}) {
  try {
    const {
      payload: { state, startWith },
    } = action
    const { data } = yield SchoolRequestHelper.request(
      SchoolsGraphql.GetSuburbsByStatesAndStartWithLetter,
      {
        state,
        startWith,
      }
    )
    const {
      getSuburbsByStateAndStartWithLetter: { suburbs },
    } = data
    yield put(ac(GET_SUBURBS_BY_STATE_SUCCESS, { suburbs }))
  } catch (ex) {
    console.log('performGetSuburbsByState ex', ex)
    yield put(ac(GET_SUBURBS_BY_STATE_FAILED, { ex }))
  }
}

/* Watchers */
function* watchers() {
  yield takeLatest(GET_SUBURBS_BY_STATE, performGetSuburbsByState)
}

export default [watchers()]
