import { CONFIG } from '../../config'
import { RequestHelper } from './RequestHelper'

const LAPUTAN_PUBLIC_API_URL = `${CONFIG.LAPUTAN_API_URL}/public`

const PublicRequestHelper = {
  request: (query: any, variables: any) =>
    RequestHelper.graphQLRequest(LAPUTAN_PUBLIC_API_URL, query, variables),
}

export { PublicRequestHelper }
