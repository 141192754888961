import { ac } from '../../actions'

export const CREATE_CHANGE_REQUEST = 'CREATE_CHANGE_REQUEST'
export const CREATE_CHANGE_REQUEST_SUCCESS = 'CREATE_CHANGE_REQUEST_SUCCESS'
export const CREATE_CHANGE_REQUEST_FAILED = 'CREATE_CHANGE_REQUEST_FAILED'

export const createChangeRequest = ({
  type,
  contact,
  data,
  success,
  fail,
}: {
  type: CHANGE_REQUEST_TYPE
  contact: object
  data: object
  success: () => void
  fail: () => void
}) => ac(CREATE_CHANGE_REQUEST, { type, contact, data, success, fail })
