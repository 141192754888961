import { SagaRequestHelper } from './SagaRequestHelper'
import { ChangeRequestsRequestHelper } from '../RequestHelper/ChangeRequestsRequestHelper'

const ChangeRequestSagaRequestHelper = {
  request: function*(props: any) {
    yield SagaRequestHelper.request({
      requester: ChangeRequestsRequestHelper.request,
      ...props,
    })
  },
}

export { ChangeRequestSagaRequestHelper }
