import { SchoolAdminGraphql } from '@laputan/graphql/dist/SchoolAdmin.graphql'
import { takeLeading } from 'redux-saga/effects'
import { GET_POPULAR_SUBURBS_BY_STATE } from './actions'
import { SchoolAdminSagaRequestHelper } from '../../../helpers/SagaRequestHelper/SchoolAdminSagaRequestHelper'

export function* performGetPopularSuburbsByState(action: {
  payload: { state: string }
  type: string
}) {
  yield SchoolAdminSagaRequestHelper.request({
    action,
    graphql: SchoolAdminGraphql.GetTopSuburbsByState,
  })
}

/* Watchers */
function* watchers() {
  yield takeLeading(
    GET_POPULAR_SUBURBS_BY_STATE,
    performGetPopularSuburbsByState
  )
}

export default [watchers()]
