enum ModalType {
  LOGIN = 'LOGIN',
  SIGN_UP = 'SIGN_UP',
  FORGOT = 'FORGOT',
  LOADING = 'LOADING',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CREATE_SCHOOL_LISTING_CATEGORY = 'CREATE_SCHOOL_LISTING_CATEGORY',
  SCHOOL_LISTING_VIEW_ALL_MODAL = 'SCHOOL_LISTING_VIEW_ALL_MODAL',
  SCHOOL_LISTING_DETAILS_MODAL = 'SCHOOL_LISTING_DETAILS_MODAL',
  SCHOOL_LISTING_DELETE = 'SCHOOL_LISTING_DELETE',
  CHANGE_REQUEST_DELETE = 'CHANGE_REQUEST_DELETE',
  EXTERNAL_WEBSITE = 'EXTERNAL_WEBSITE',
  ADD_LISTING = 'ADD_LISTING',
  UPDATE_LISTING = 'UPDATE_LISTING',
  CHANGE_REQUEST_SUBMITTED = 'CHANGE_REQUEST_SUBMITTED',
  NEWS = 'NEWS',
  PAYMENT = 'PAYMENT',
  ADD_FROM_MEDIA_LIBRARY = 'ADD_FROM_MEDIA_LIBRARY',
  GET_CODE = 'GET_CODE',
  THANK_YOU_SIGNUP = 'THANK_YOU_SIGNUP',
  ADD_VIDEO = 'ADD_VIDEO',
  APPOINTMENT = 'APPOINTMENT',
  VIDEO_CALL = 'VIDEO_CALL',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  EDIT_SCHOOL = 'EDIT_SCHOOL',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  MESSAGE = 'MESSAGE',
}

export { ModalType }
