import {
  GET_POPULAR_SUBURBS_BY_STATE,
  GET_POPULAR_SUBURBS_BY_STATE_FAILED,
  GET_POPULAR_SUBURBS_BY_STATE_SUCCESS,
} from './actions'

export interface PopularSuburbsState {
  isFetching: boolean
  list?: Array<any>
}

const initialState = {
  isFetching: false,
  list: [],
}

const popularSuburbs = (
  state: any = initialState,
  { type, payload }: any
): PopularSuburbsState => {
  switch (type) {
    case GET_POPULAR_SUBURBS_BY_STATE: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case GET_POPULAR_SUBURBS_BY_STATE_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    case GET_POPULAR_SUBURBS_BY_STATE_SUCCESS: {
      return {
        ...state,
        list: payload.suburbs,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export { popularSuburbs, initialState }
