import { ac } from '../actions'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'
export const GET_CURRENT_USER_INFO = 'GET_CURRENT_USER_INFO'
export const GET_CURRENT_USER_INFO_SUCCESS = 'GET_CURRENT_USER_INFO_SUCCESS'
export const GET_CURRENT_USER_INFO_FAILED = 'GET_CURRENT_USER_INFO_FAILED'
export const UPDATE_CURRENT_USER_PROFILE = 'UPDATE_CURRENT_USER_PROFILE'
export const UPDATE_CURRENT_USER_PROFILE_SUCCESS =
  'UPDATE_CURRENT_USER_PROFILE_SUCCESS'
export const UPDATE_CURRENT_USER_PROFILE_FAILED =
  'UPDATE_CURRENT_USER_PROFILE_FAILED'
export const COMPLETE_NEW_PASSWORD = 'COMPLETE_NEW_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD_SUBMIT'
export const RESEND_SIGN_UP_CONFIRMATION = 'RESEND_SIGN_UP_CONFIRMATION'
export const UPDATE_LOCAL_USER_PROFILE = 'UPDATE_LOCAL_USER_PROFILE'

export const login = (email: string, password: string) =>
  ac(LOGIN, { email, password })
export const logout = () => ac(LOGOUT, {})
export const getCurrentUserInfo = () => ac(GET_CURRENT_USER_INFO)
export const updateCurrentUserProfile = (user: any) =>
  ac(UPDATE_CURRENT_USER_PROFILE, { user })
export const completeNewPassword = (password: string) =>
  ac(COMPLETE_NEW_PASSWORD, { password })
export const changePassword = (oldPassword: string, newPassword: string) =>
  ac(CHANGE_PASSWORD, { oldPassword, newPassword })
export const forgotPassword = (email: string) => ac(FORGOT_PASSWORD, { email })
export const forgotPasswordSubmit = (
  email: string,
  newPassword: string,
  verificationCode: string
) => ac(FORGOT_PASSWORD_SUBMIT, { email, newPassword, verificationCode })
export const updateLocalUserProfile = ({
  email,
  jwtToken,
  refreshToken,
}: {
  email: string
  jwtToken: string
  refreshToken: string
}) => ac(UPDATE_LOCAL_USER_PROFILE, { email, jwtToken, refreshToken })

export const resendSignUpConfirmationCode = ({ email }: { email: string }) =>
  ac(RESEND_SIGN_UP_CONFIRMATION, { email })
