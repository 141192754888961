import { ac } from '../actions'
import { IEditSchoolFormFields } from '../../types/SchoolAdmin.types'

export const SEARCH_SCHOOLS = 'SEARCH_SCHOOLS'
export const SEARCH_SCHOOLS_SUCCESS = 'SEARCH_SCHOOLS_SUCCESS'
export const SEARCH_SCHOOLS_FAILED = 'SEARCH_SCHOOLS_FAILED'
export const VERIFY_SCHOOL_REQUEST = 'VERIFY_SCHOOL_REQUEST'
export const VERIFY_SCHOOL_REQUEST_SUCCESS = 'VERIFY_SCHOOL_REQUEST_SUCCESS'
export const VERIFY_SCHOOL_REQUEST_FAILED = 'VERIFY_SCHOOL_REQUEST_FAILED'
export const UPDATE_SCHOOL_FOR_BASIC_PLAN_USER =
  'UPDATE_SCHOOL_FOR_BASIC_PLAN_USER'
export const UPDATE_SCHOOL_FOR_BASIC_PLAN_USER_SUCCESS =
  'UPDATE_SCHOOL_FOR_BASIC_PLAN_USER_SUCCESS'
export const UPDATE_SCHOOL_FOR_BASIC_PLAN_USER_FAILED =
  'UPDATE_SCHOOL_FOR_BASIC_PLAN_USER_FAILED'
export const GET_SCHOOL_BY_ID = 'GET_SCHOOL_BY_ID'
export const GET_SCHOOL_BY_ID_SUCCESS = 'GET_SCHOOL_BY_ID_SUCCESS'
export const GET_SCHOOL_BY_ID_FAILED = 'GET_SCHOOL_BY_ID_FAILED'
export const GET_TOP_SCHOOLS_IN_STATES = 'GET_TOP_SCHOOLS_IN_STATES'
export const GET_TOP_SCHOOLS_IN_STATES_SUCCESS =
  'GET_TOP_SCHOOLS_IN_STATES_SUCCESS'
export const GET_TOP_SCHOOLS_IN_STATES_FAILED =
  'GET_TOP_SCHOOLS_IN_STATES_FAILED'
export const GET_TOP_SUBURBS_IN_STATES = 'GET_TOP_SUBURBS_IN_STATES'
export const GET_TOP_SUBURBS_IN_STATES_SUCCESS =
  'GET_TOP_SUBURBS_IN_STATES_SUCCESS'
export const GET_TOP_SUBURBS_IN_STATES_FAILED =
  'GET_TOP_SUBURBS_IN_STATES_FAILED'
export const CLEAR_SCHOOL_LIST = 'CLEAR_SCHOOL_LIST'
export const SHOW_SCHOOL_LOGIN_MODAL = 'SHOW_SCHOOL_LOGIN_MODAL'

export const UPLOAD_SCHOOL_LOGO = 'UPLOAD_SCHOOL_LOGO'
export const UPLOAD_SCHOOL_LOGO_SUCCESS = 'UPLOAD_SCHOOL_LOGO_SUCCESS'
export const UPLOAD_SCHOOL_LOGO_FAILED = 'UPLOAD_SCHOOL_LOGO_FAILED'

export const searchSchools = ({
  page,
  state,
  search,
  sortBy,
  coords,
  levels,
  sector,
  gender,
}: any) =>
  ac(SEARCH_SCHOOLS, {
    page,
    state,
    search,
    sortBy,
    coords,
    levels,
    sector,
    gender,
  })
export const getSchoolById = (id: string) => ac(GET_SCHOOL_BY_ID, { id })
export const getTopSchoolsInStates = () => ac(GET_TOP_SCHOOLS_IN_STATES)
export const getTopSuburbsInStates = () => ac(GET_TOP_SUBURBS_IN_STATES)
export const clearSchoolList = () => ac(CLEAR_SCHOOL_LIST)
export const updateSchoolForBasicPlanUser = (
  schoolId: string,
  schoolData: IEditSchoolFormFields,
  success: () => void,
  complete: () => void
) =>
  ac(UPDATE_SCHOOL_FOR_BASIC_PLAN_USER, {
    schoolId,
    schoolData,
    success,
    complete,
  })

export const showSchoolLoginModal = () => ac(SHOW_SCHOOL_LOGIN_MODAL)

export const uploadSchoolLogo = (image: {
  file: any
  contentType: string
  success: ({
    preSignedUrl,
    urlPath,
  }: {
    preSignedUrl: string
    urlPath: string
  }) => void
}) => ac(UPLOAD_SCHOOL_LOGO, image)

export const verifySchoolRequest = ({
  schoolId,
  success,
  fail,
}: {
  schoolId: string
  success: () => void
  fail: () => void
}) => ac(VERIFY_SCHOOL_REQUEST, { schoolId, success, fail })
