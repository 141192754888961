const STYLES = {
  PRIMARY_COLOR: '#F0B90B',
  LIGHTER_PRIMARY_COLOR: '#f6e9c1',

  COLOR_WHITE: '#fff',
  COLOR_BLACK: '#000',
  COLOR_TIBER_BLUE: '#052332',
  COLOR_CERISE_PINK: '#e42486',
  COLOR_SIREN_PURPLE: '#7B003F',
  COLOR_LIGHT_SIREN_PURPLE: '#561134',
  COLOR_OUTER_SPACE_GRAY: '#2f353b',
  COLOR_LIGHT_OUTER_SPACE_GRAY: '#374149',
  COLOR_HEAVY_OUTER_SPACE_GRAY: '#2f353b',
  COLOR_SHUTTLE_GRAY: '#5a6268',
  COLOR_PALE_SKY_GRAY: '#6d777e',
  COLOR_GRAY_CHATEAU: '#9da7ae',
  COLOR_HIT_GRAY: '#adb5ba',
  COLOR_MERCURY_GRAY: '#e6e6e6',
  COLOR_ATHENS_GRAY: '#F3F3F4',
  COLOR_AQUA_HAZE_BLUE: '#f6f8fa',
  COLOR_MOUNTAIN_MEADOW_GREEN: '#15ae5a',
  COLOR_MOUNTAIN_MEADOW_GREE: '#4e366d',
  COLOR_JEWEL_GREEN: '#0f763f',
  COLOR_BOSTON_BLUE: '#428bca',
  COLOR_GURKHA_BROWN: '#86675e',
  COLOR_DOUBLE_SPANISH_WHITE: '#e3dcd7',
  COLOR_AMBER_ORANGE: '#ffad24',
  COLOR_BITTER_SWEET_RED: '#FF6363',
  COLOR_ALANTIS_GREEN: '#8FB72A',
  COLOR_VIVID_RED: '#DB3737',
  COLOR_BUTTERCUP: '#F0B90B',
  COLOR_LIGHT_BUTTERCUP: '#e7dab3',
  COLOR_PINE_GREEN: '#006B73',
  COLOR_GUARDSMAN_RED: '#BB0000',
  COLOR_PIRATE_GOLD: '#B98800',

  SCHOOL_DETAILS_SECTION_GUTTER: '.5rem 0',
  BADGE_CONTENT_PADDING: '.25rem .5rem',
  BADGE_MARGIN: '.15rem .5rem .15rem 0',
  BADGE_LINE_HEIGHT: '1rem',
  BORDER_COLOR: '#cccccc',
  BORDER_RADIUS: '.25rem',
  TEXT_COLOR: '#222',
  TABLE_ROW_HIGHLIGHT_COLOR: '#F3F3F4',
  ICON_HOVER_COLOR: 'red',

  FIELD_PADDING: '.5rem .5rem',
  FIELD_BORDER_COLOR: '#cccccc',
  FIELD_BORDER_RADIUS: '.25rem',

  CONTENT_PADDING: '1rem',
  TOPNAV_HEIGHT: '2.8rem',
  LINK_COLOR: '#000',
  LINK_HOVER_COLOR: '#F0B90B',
  BUTTON_GRAY_BORDER_COLOR: '#2f353b',
  BUTTON_GRAY_BORDER_HOVER_COLOR: '#2f353b',
  BUTTON_GRAY_COLOR: '#2f353b',
  BUTTON_GRAY_BACKGROUND_IMAGE: 'linear-gradient(-180deg,#2f353b,#374149 90%)',
  BUTTON_GRAY_HOVER_COLOR: '#2f353b',
  BUTTON_GRAY_HOVER_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#374149,#2f353b 90%)',
  BUTTON_BLUE_BORDER_COLOR: '#4867AA',
  BUTTON_BLUE_BORDER_HOVER_COLOR: '#4867AA',
  BUTTON_BLUE_COLOR: '#3f5b9b',
  BUTTON_BLUE_BACKGROUND_IMAGE: 'linear-gradient(-180deg,#3f5b9b,#4867AA 90%)',
  BUTTON_BLUE_HOVER_COLOR: '#4867AA',
  BUTTON_BLUE_HOVER_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#4867AA,#3f5b9b 90%)',
  BUTTON_RED_BORDER_COLOR: '#C94130',
  BUTTON_RED_BORDER_HOVER_COLOR: '#C94130',
  BUTTON_RED_COLOR: '#b34334',
  BUTTON_RED_BACKGROUND_IMAGE: 'linear-gradient(-180deg,#b34334,#C94130 90%)',
  BUTTON_RED_HOVER_COLOR: '#C94130',
  BUTTON_RED_HOVER_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#C94130,#b34334 90%)',
  BUTTON_PRIMARY_BORDER_COLOR: '#fccc37',
  BUTTON_PRIMARY_BORDER_HOVER_COLOR: '#fccc37',
  BUTTON_PRIMARY_COLOR: '#F0B90B',
  BUTTON_PRIMARY_TEXT_COLOR: '#000',
  BUTTON_PRIMARY_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#F0B90B,#F0B90B 90%)',
  BUTTON_PRIMARY_HOVER_COLOR: '#F0B90B',
  BUTTON_PRIMARY_HOVER_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#fccc37,#fccc37 90%)',
  BUTTON_SECONDARY_BORDER_COLOR: '#898888',
  BUTTON_SECONDARY_BORDER_HOVER_COLOR: '#F0B90B',
  BUTTON_SECONDARY_COLOR: '#333',
  BUTTON_SECONDARY_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#ebeced,#cbcccd 90%)',
  BUTTON_SECONDARY_HOVER_COLOR: '#F0B90B',
  BUTTON_SECONDARY_HOVER_BACKGROUND_IMAGE:
    'linear-gradient(-180deg,#ebeced,#cbcccd 90%)',
  BUTTON_FONT_SIZE_SM: '.875rem',
  BUTTON_FONT_SIZE_MD: '1rem',
  BUTTON_FONT_SIZE_LG: '1.25rem',

  BUTTON_PADDING_SM: '.25rem .5rem',
  BUTTON_PADDING_MD: '.5rem .75rem',
  BUTTON_PADDING_LG: '.75rem 1rem',
  CONTENT_MAX_WIDTH: '60rem',

  GUTTER: '.5rem',
  LIST_ITEM_MARGIN: '.2rem',
  LIST_ITEM_PADDING: '.5rem',
  FONT_FAMILY_ITALIC: 'Shadows Into Light Two',
  FONT_SIZE_XS: '0.725rem',
  FONT_SIZE_SM: '0.825rem',
  FONT_SIZE_MD: '1rem',
  FONT_SIZE_LG: '1.25rem',

  FONT_SIZE_XL: '1.5rem',
  FONT_SIZE_XXL: '3rem',
  FONT_SIZE_XXXL: '4.6rem',

  FONT_WEIGHT_LITE: 300,
  FONT_WEIGHT_NORMAL: 400,
  FONT_WEIGHT_BOLD: 600,

  PAGE_BORDER_COLOR: '#e8e8e8',

  HEADER_1_FONT_SIZE: '2rem',
  HEADER_2_FONT_SIZE: '1.6rem',
  HEADER_3_FONT_SIZE: '1.5rem',
  HEADER_4_FONT_SIZE: '1.5rem',
  HEADER_5_FONT_SIZE: '1.3rem',
  HEADER_6_FONT_SIZE: '1.2rem',

  HEADER_1_LINE_HEIGHT: '2.6rem',
  HEADER_2_LINE_HEIGHT: '2.2rem',
  HEADER_3_LINE_HEIGHT: '2rem',
  HEADER_4_LINE_HEIGHT: '2rem',
  HEADER_5_LINE_HEIGHT: '1.8rem',
  HEADER_6_LINE_HEIGHT: '1.6rem',

  FORM_FIELD_MARGIN: '.5rem 0',

  MOBILE_SCREEN_CONTENT_PADDING: '2rem',
  MAX_FORM_WIDTH: '40rem',
}

const BREAKPOINT = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
}

const BREAKPOINTS: any = {}
Object.keys(BREAKPOINT).map((key: string) => (BREAKPOINTS[key] = key))

const SCREEN_SIZE = {
  XS: `@media screen and (min-width: ${BREAKPOINT.XS}px)`,
  SM: `@media screen and (min-width: ${BREAKPOINT.SM}px)`,
  MD: `@media screen and (min-width: ${BREAKPOINT.MD}px)`,
  LG: `@media screen and (min-width: ${BREAKPOINT.LG}px)`,
  XL: `@media screen and (min-width: ${BREAKPOINT.XL}px)`,
}

export { BREAKPOINT, BREAKPOINTS, STYLES, SCREEN_SIZE }
