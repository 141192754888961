const en = {
  GENERAL_REQUIRED_VALIDATION: '* required',
  GENERAL_INVALID_VALIDATION: '* invalid',
  GENERAL_EMAIL_VALIDATION: 'invalid email format',
  GENERAL_PHONE_VALIDATION: 'invalid phone number format',
  PROFILE_PASSWORD_POLICY: 'must be at least 8 characters',
  PROFILE_PROJECT_DEADLINE_POLICY: 'Project deadline must be a date in future',
  PROFILE_REPEAT_PASSWORD_VALIDATION: 'does not match the password',
  UsernameExistsException: 'An account with this email already exists',
  NotAuthorizedException: 'Incorrect email or password',
  UPDATED_USER_PROFILE_SUCCESSFULLY: 'Updated user profile successfully',
  FAILED_TO_UPDATE_USER_PROFILE: 'Failed to update your profile',
  FAILED_TO_CREATE_PROJECT: 'Failed to create a project',
  CREATED_PROJECT_SUCCESSFULLY: 'Project created successfully',
  FAILED_TO_GET_PROJECT: 'Failed to get this project',
  FAILED_TO_SUBMIT_ENQUIRY:
    'Sorry, failed to submit your enquiry. Please try again later.',
  FAILED_TO_SUBMIT_DATA_SALES_ENQUIRY:
    'Sorry, failed to submit your enquiry. Please try again later.',
  VERIFICATION_CODE_VALIDATION: 'must be a 6-digit number',
  COORDINATE_VALIDATION: 'must be a valid coordinate',
}

export { en }
