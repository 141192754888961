import { RequestHelper } from './RequestHelper'
import { CONFIG } from '../../config'
import { AuthHelper } from '../AuthHelper'

const LAPUTAN_ADMIN_API_URL = `${CONFIG.LAPUTAN_API_URL}/admin`

const AdminRequestHelper = {
  request: async (query: any, variables?: any) => {
    const headers = await AuthHelper.getAuthHeader()
    return RequestHelper.graphQLRequest(
      LAPUTAN_ADMIN_API_URL,
      query,
      variables,
      headers
    )
  },
}

export { AdminRequestHelper }
