import { ac } from '../../actions'

export const SCHOOL_AUTO_SUGGEST_SEARCH = 'SCHOOL_AUTO_SUGGEST_SEARCH'
export const SCHOOL_AUTO_SUGGEST_SEARCH_SUCCESS =
  'SCHOOL_AUTO_SUGGEST_SEARCH_SUCCESS'
export const SCHOOL_AUTO_SUGGEST_SEARCH_FAILED =
  'SCHOOL_AUTO_SUGGEST_SEARCH_FAILED'
export const CLEAR_AUTO_SUGGEST = 'CLEAR_AUTO_SUGGEST'

export const schoolAutoSuggestSearch = (query: any) =>
  ac(SCHOOL_AUTO_SUGGEST_SEARCH, { query })
export const clearAutoSuggest = () => ac(CLEAR_AUTO_SUGGEST)
