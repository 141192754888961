import { CONFIG } from '../../config'
import { RequestHelper } from './RequestHelper'

const LAPUTAN_SUBSCRIPTIONS_API_URL = `${CONFIG.LAPUTAN_API_URL}/subscriptions`

const SubscriptionRequestHelper = {
  request: (query: any, variables: any) =>
    RequestHelper.graphQLRequest(
      LAPUTAN_SUBSCRIPTIONS_API_URL,
      query,
      variables
    ),
}

export { SubscriptionRequestHelper }
