import { takeLatest } from 'redux-saga/effects'
import { SubscriptionsGraphql } from '@laputan/graphql/dist/Subscriptions.graphql'
import { CREATE_SUBSCRIPTION } from './actions'
import { SubscriptionSagaRequestHelper } from '../../helpers/SagaRequestHelper/SubscriptionSagaRequestHelper'

export function* performCreateSubscription(action: any) {
  yield SubscriptionSagaRequestHelper.request({
    action,
    graphql: SubscriptionsGraphql.CreateSubscription,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(CREATE_SUBSCRIPTION, performCreateSubscription)
}

export default [watchers()]
