export enum AlertType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  URGENT = 'URGENT',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export interface IAlertMessage {
  message: string
  type: AlertType
  id: string
}

export interface AlertTypes {
  children: React.ReactNode
  type: AlertType
  onCloseClick: () => void
  onMounted: () => void
}
