import { takeLatest } from 'redux-saga/effects'
import { SchoolsGraphql } from '@laputan/graphql/dist/Schools.graphql'
import { FETCH_SCHOOL_POST_BY_ID, FETCH_SCHOOL_POSTS } from './actions'
import { SchoolSagaRequestHelper } from '../../../helpers/SagaRequestHelper/SchoolSagaRequestHelper'

export function* performFetchSchoolPosts(action: any) {
  yield SchoolSagaRequestHelper.request({
    action,
    graphql: SchoolsGraphql.GetSchoolPosts,
  })
}

export function* performFetchSchoolPostById(action: any) {
  yield SchoolSagaRequestHelper.request({
    action,
    graphql: SchoolsGraphql.GetSchoolPostById,
  })
}

/* Watchers */
function* watchers() {
  yield takeLatest(FETCH_SCHOOL_POSTS, performFetchSchoolPosts)
  yield takeLatest(FETCH_SCHOOL_POST_BY_ID, performFetchSchoolPostById)
}

export default [watchers()]
