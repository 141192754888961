import { mergeRight } from '../../../lib/ramda'
import {
  CONNECT_CREATE_MEDIA_ITEMS,
  CONNECT_CREATE_MEDIA_ITEMS_FAILED,
  CONNECT_CREATE_MEDIA_ITEMS_SUCCESS,
  CONNECT_MEDIA_IMAGE_UPLOAD,
  CONNECT_MEDIA_IMAGE_UPLOAD_FAILED,
  CONNECT_MEDIA_IMAGE_UPLOAD_SUCCESS,
  CONNECT_REMOVE_MEDIA_ITEM_BY_ID,
  CONNECT_REMOVE_MEDIA_ITEM_BY_ID_FAILED,
  CONNECT_REMOVE_MEDIA_ITEM_BY_ID_SUCCESS,
  CONNECT_SEARCH_MEDIA_ITEMS,
  CONNECT_SEARCH_MEDIA_ITEMS_FAILED,
  CONNECT_SEARCH_MEDIA_ITEMS_SUCCESS,
} from './actions'
import { IMediaItemProps } from '../../../types/MediaItem.types'

export interface ConnectMediaState {
  isFetching: boolean
  isUploading: boolean
  isRemoving: boolean
  isCreating?: boolean
  list: IMediaItemProps[] | []
}
const initialState: ConnectMediaState = {
  isFetching: false,
  isUploading: false,
  isRemoving: false,
  list: [],
}

const connectMedia = (
  state = initialState,
  { type, payload }: any
): ConnectMediaState => {
  switch (type) {
    case CONNECT_SEARCH_MEDIA_ITEMS:
      return mergeRight(state, {
        isFetching: true,
      })
    case CONNECT_SEARCH_MEDIA_ITEMS_SUCCESS:
      return mergeRight(state, {
        isFetching: false,
        list: payload.mediaItems,
      })
    case CONNECT_SEARCH_MEDIA_ITEMS_FAILED:
      return mergeRight(state, {
        isFetching: false,
      })
    case CONNECT_CREATE_MEDIA_ITEMS:
      return mergeRight(state, {
        isCreating: true,
      })
    case CONNECT_CREATE_MEDIA_ITEMS_SUCCESS: {
      return mergeRight(state, {
        isCreating: false,
        list: payload.mediaItems.concat(state.list),
      })
    }
    case CONNECT_CREATE_MEDIA_ITEMS_FAILED: {
      return mergeRight(state, {
        isCreating: false,
      })
    }
    case CONNECT_MEDIA_IMAGE_UPLOAD: {
      return mergeRight(state, {
        isUploading: true,
      })
    }
    case CONNECT_MEDIA_IMAGE_UPLOAD_SUCCESS: {
      return mergeRight(state, {
        isUploading: false,
      })
    }
    case CONNECT_MEDIA_IMAGE_UPLOAD_FAILED: {
      return mergeRight(state, {
        isUploading: false,
      })
    }
    case CONNECT_REMOVE_MEDIA_ITEM_BY_ID: {
      return mergeRight(state, {
        isRemoving: true,
      })
    }
    case CONNECT_REMOVE_MEDIA_ITEM_BY_ID_SUCCESS: {
      return mergeRight(state, {
        isRemoving: false,
        list: state.list.filter((i: any) => i.id !== payload.mediaItem.id),
      })
    }
    case CONNECT_REMOVE_MEDIA_ITEM_BY_ID_FAILED: {
      return mergeRight(state, {
        isRemoving: false,
      })
    }
    default:
      return state
  }
}

export { connectMedia, initialState }
