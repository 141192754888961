import qs from 'query-string'
import { NavigationHelper } from './NavigationHelper'
const UtilHelper = {
  debounce: (func: any, wait: any, immediate: any) => {
    let timeout: any
    return function debouncedFunc(...args: any[]) {
      // @ts-ignore
      const context = this

      function later() {
        timeout = null
        if (!immediate) {
          func.apply(context, args)
        }
      }

      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) {
        func.apply(context, args)
      }
    }
  },
}

const getWindow = () => (typeof window !== 'undefined' ? window : null)

const getLocation = () => (getWindow() ? getWindow()?.location : null)

const getQueryString = (params?: any): string | null => {
  if (params) {
    return qs.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    })
  }
  const location = getLocation()
  return location ? location.search.replace('?', '') : null
}

export const updateAndNavigateByQueryString = (params?: any) => {
  const win = getWindow()
  if (!win) {
    return
  }
  const currentQuery = qs.parse(win.location.search)
  const queryString = qs.stringify({ ...currentQuery, ...params })
  const path = `${location.pathname}?${queryString}`
  NavigationHelper.navigate(path)
}

const getQuery = (params?: any): object | undefined => {
  const queryString = getQueryString(params)
  if (queryString) {
    return qs.parse(queryString)
  }
  return
}

const getUrlWithQueryString = (url: string, params?: any): string => {
  const queryString = getQueryString(params)
  if (!queryString) {
    return url
  }
  return `${url}?${queryString}`
}

// @ts-ignore
const getCurrentUrl = () => (getLocation() ? getLocation()?.url : null)

// matrix(1, 0, 0, 1, 14, 0)
const getValueFromMatrix = (matrix?: any) => {
  if (!matrix) {
    return
  }

  const matches = matrix.match(
    /([-.0-9]+)\s*,\s*([-.0-9]+)\s*,\s*([-.0-9]+)\s*,\s*([-.0-9]+)\s*,\s*([-.0-9]+)\s*,\s*([-.0-9]+)\s*/
  )
  if (!matches) {
    return
  }
  return {
    a: parseFloat(matches[1]),
    b: parseFloat(matches[2]),
    c: parseFloat(matches[3]),
    d: parseFloat(matches[4]),
    tx: parseFloat(matches[5]),
    ty: parseFloat(matches[6]),
  }
}

export {
  UtilHelper,
  getWindow,
  getQueryString,
  getQuery,
  getUrlWithQueryString,
  getCurrentUrl,
  getValueFromMatrix,
  getLocation,
}
