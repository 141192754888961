export const STATE_TO_TEXT_MAP: { [key: string]: string } = {
  NSW: 'New South Wales',
  VIC: 'Victoria',
  ACT: 'Australian Capital Territory',
  QLD: 'Queensland',
  WA: 'Western Australia',
  SA: 'South Australia',
  TAS: 'Tasmania',
  NT: 'Northern Territory',
}

export const STATE_TO_TEXT: Array<{ displayText: string; value: string }> = [
  { displayText: STATE_TO_TEXT_MAP.NSW, value: 'NSW' },
  { displayText: STATE_TO_TEXT_MAP.VIC, value: 'VIC' },
  { displayText: STATE_TO_TEXT_MAP.ACT, value: 'ACT' },
  { displayText: STATE_TO_TEXT_MAP.QLD, value: 'QLD' },
  { displayText: STATE_TO_TEXT_MAP.WA, value: 'WA' },
  { displayText: STATE_TO_TEXT_MAP.SA, value: 'SA' },
  { displayText: STATE_TO_TEXT_MAP.TAS, value: 'TAS' },
  { displayText: STATE_TO_TEXT_MAP.NT, value: 'NT' },
]
