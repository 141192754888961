import locale from './locale'

const DEFAULT_LOCALE = 'en'

const LocaleHelper = {
  locale: undefined,

  setLocale: (l: any) => {
    LocaleHelper.locale = l
  },

  getLocale: () => LocaleHelper.locale || DEFAULT_LOCALE,

  // @ts-ignore
  getText: (key: string) => locale[LocaleHelper.getLocale()][key],
}

export { LocaleHelper }
