import { ac } from '../actions'

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS'
export const CREATE_SUBSCRIPTION_FAILED = 'CREATE_SUBSCRIPTION_FAILED'

export const createSubscription = ({
  domain,
  email,
  firstName,
  lastName,
  options,
  success,
  fail,
}: any) =>
  ac(CREATE_SUBSCRIPTION, {
    domain,
    email,
    firstName,
    lastName,
    options,
    success,
    fail,
  })
