export { default as path } from 'ramda/src/path'
export { default as pathOr } from 'ramda/src/pathOr'
export { default as mergeRight } from 'ramda/src/mergeRight'
export { default as mergeDeepRight } from 'ramda/src/mergeDeepRight'
export { default as omit } from 'ramda/src/omit'
export { default as take } from 'ramda/src/take'
export { default as intersperse } from 'ramda/src/intersperse'
export { default as isNil } from 'ramda/src/isNil'
export { default as pick } from 'ramda/src/pick'
export { default as without } from 'ramda/src/without'
export { default as contains } from 'ramda/src/includes'
export { default as sortWith } from 'ramda/src/sortWith'
export { default as sort } from 'ramda/src/sort'
export { default as prop } from 'ramda/src/prop'
export { default as ascend } from 'ramda/src/ascend'
export { default as descend } from 'ramda/src/descend'
export { default as values } from 'ramda/src/values'
export { default as times } from 'ramda/src/times'
export { default as reverse } from 'ramda/src/reverse'
export { default as find } from 'ramda/src/find'
export { default as slice } from 'ramda/src/slice'
export { default as filter } from 'ramda/src/filter'
export { default as assocPath } from 'ramda/src/assocPath'
export { default as range } from 'ramda/src/range'
export { default as is } from 'ramda/src/is'
export { default as uniq } from 'ramda/src/uniq'

export const upsert = (list: any, newItem: any, identifier = 'id') => {
  let updated = false
  const newList = list.map((item: any) => {
    if (item[identifier] === newItem[identifier]) {
      updated = true
      return newItem
    }
    return item
  })
  if (!updated) {
    newList.push(newItem)
  }
  return newList
}

export const orderFromList = (specifiedOrderedList: any, list: any) => {
  return list.sort(
    (a: any, b: any) =>
      specifiedOrderedList.indexOf(a) - specifiedOrderedList.indexOf(b)
  )
}
