import { hideLoadingBar, showLoadingBar } from '../../states/loadingBar/actions'

const defaultRequestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

const RequestHelper = {
  request: async (options: any) => {
    const { store } = require('../../states/store')
    store.dispatch(showLoadingBar())
    const opt: any = Object.assign({}, defaultRequestOptions)
    if (options.headers) {
      opt.headers = Object.assign(opt.headers, options.headers)
    }
    opt.body = JSON.stringify({
      query: options.query,
      variables: options.variables,
    })
    return fetch(options.url, opt).then((r) => {
      store.dispatch(hideLoadingBar())
      return r.json()
    })
  },

  graphQLRequest: (
    url: string,
    query?: any,
    variables?: any,
    headers?: any
  ) => {
    return RequestHelper.request({
      url,
      query,
      variables: variables || {},
      headers,
    }).then(({ data, errors }) => {
      if (errors) {
        throw new Error(errors[0].message)
      }
      return data
    })
  },
}

export { RequestHelper }
