import { takeLatest, put } from 'redux-saga/effects'
import { PublicRequestGraphql } from '@laputan/graphql/dist/PublicRequests.graphql'
import {
  SUBMIT_ENQUIRY,
  SUBMIT_ENQUIRY_SUCCESS,
  SUBMIT_ENQUIRY_FAILED,
  SUBMIT_DATA_SALES_ENQUIRY_SUCCESS,
  SUBMIT_DATA_SALES_ENQUIRY_FAILED,
  SUBMIT_DATA_SALES_ENQUIRY,
} from './actions'
import { ac } from '../actions'
import { showErrorAlert } from '../alerts/actions'
import { LocaleHelper } from '../../helpers/LocaleHelper'
import { PublicRequestHelper } from '../../helpers/RequestHelper/PublicRequestHelper'
import { DataSalesEnquiry } from '@laputan/core/dist/types/Enquiry.types'

export function* performSubmitEnquiry(action: any) {
  const {
    payload: { enquiry },
  } = action
  const {
    submitEnquiry: { success },
  } = yield PublicRequestHelper.request(
    PublicRequestGraphql.SubmitEnquiryRequest,
    enquiry
  )

  if (success) {
    yield put(ac(SUBMIT_ENQUIRY_SUCCESS))
  } else {
    yield put(ac(SUBMIT_ENQUIRY_FAILED))
    yield put(showErrorAlert(LocaleHelper.getText('FAILED_TO_SUBMIT_ENQUIRY')))
  }
}

export function* performDataSalesSubmitEnquiry(action: {
  payload: {
    enquiry: DataSalesEnquiry
    onSuccess: () => void
    onFailed: () => void
  }
}) {
  const {
    payload: { enquiry, onSuccess, onFailed },
  } = action
  const {
    submitDataSalesEnquiry: { success },
  } = yield PublicRequestHelper.request(
    PublicRequestGraphql.SubmitDataSalesEnquiryRequest,
    enquiry
  )

  if (success) {
    yield put(ac(SUBMIT_DATA_SALES_ENQUIRY_SUCCESS))
    if (onSuccess) {
      onSuccess()
    }
  } else {
    yield put(ac(SUBMIT_DATA_SALES_ENQUIRY_FAILED))
    yield put(
      showErrorAlert(
        LocaleHelper.getText('FAILED_TO_SUBMIT_DATA_SALES_ENQUIRY')
      )
    )
    if (onFailed) {
      onFailed()
    }
  }
}

/* Watchers */
function* watchSubmitEnquiry() {
  yield takeLatest(SUBMIT_ENQUIRY, performSubmitEnquiry)
  yield takeLatest(SUBMIT_DATA_SALES_ENQUIRY, performDataSalesSubmitEnquiry)
}

export default [watchSubmitEnquiry()]
