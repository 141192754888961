import {
  PaymentAction,
  PaymentActionNoPayload,
  PaymentActionWithPayload,
} from './types'
import { IInvoice } from '@laputan/core/dist/types/Invoice.types'

export interface PaymentsState {
  isCreatingSecret: boolean
  clientSecret?: string
  fees: {
    BASIC_SCHOOL_PLAN: number
    BASIC_ACTIVITY_PLAN: number
  }
  invoices?: IInvoice[]
  isFetching?: boolean
}

export const initialState = {
  isCreatingSecret: false,
  clientSecret: undefined,
  fees: {},
  invoices: undefined,
  isFetching: false,
}

const payments = (
  state: any = initialState,
  { type, payload }: PaymentActionWithPayload | PaymentActionNoPayload
): PaymentsState => {
  switch (type) {
    case PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT:
      return { ...state, isCreatingSecret: true, clientSecret: undefined }
    case PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isCreatingSecret: false,
        clientSecret: payload.clientSecret,
      }
    case PaymentAction.CREATE_BASIC_SCHOOL_PLAN_PAYMENT_INTENT_FAILED:
      return { ...state, isCreatingSecret: false }
    case PaymentAction.GET_PAID_INVOICES: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case PaymentAction.GET_PAID_INVOICES_SUCCESS:
      return { ...state, invoices: payload.invoices, isFetching: false }
    case PaymentAction.GET_PAID_INVOICES_FAILED:
      return { ...state, isFetching: false }
    case PaymentAction.GET_SCHOOL_PLAN_FEES_SUCCESS: {
      const fees = (payload as any)?.getSchoolPlanFees?.fees
      return { ...state, fees }
    }
    default:
      return state
  }
}

export { payments }
