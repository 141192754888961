import React from 'react'
import { QueryParamProvider } from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

export const withUseQueryProvider = (Component) => {
  const WithUseQueryProvider = () => (
    <QueryParamProvider adapter={ReachAdapter}>{Component}</QueryParamProvider>
  )

  return <WithUseQueryProvider />
}
