import { SCHOOL } from '@laputan/core'
import {
  CLEAR_SCHOOL_LIST,
  GET_SCHOOL_BY_ID,
  GET_SCHOOL_BY_ID_FAILED,
  GET_SCHOOL_BY_ID_SUCCESS,
  GET_TOP_SCHOOLS_IN_STATES,
  GET_TOP_SCHOOLS_IN_STATES_FAILED,
  GET_TOP_SCHOOLS_IN_STATES_SUCCESS,
  GET_TOP_SUBURBS_IN_STATES,
  GET_TOP_SUBURBS_IN_STATES_FAILED,
  GET_TOP_SUBURBS_IN_STATES_SUCCESS,
  SEARCH_SCHOOLS,
  SEARCH_SCHOOLS_FAILED,
  SEARCH_SCHOOLS_SUCCESS,
  UPLOAD_SCHOOL_LOGO_SUCCESS,
  UPLOAD_SCHOOL_LOGO_FAILED,
} from './actions'
import { mergeRight, mergeDeepRight, upsert } from '../../lib/ramda'
import {
  GET_SCHOOL_LISTINGS_BY_SCHOOL_ID,
  GET_SCHOOL_LISTINGS_BY_SCHOOL_ID_SUCCESS,
} from './listings/actions'

export interface SchoolsState {
  isFetching: boolean
  list: Array<any>
  topSchoolsByState: object
  topSuburbsByState: object
  total?: number
  currentPage?: number
}

const initialState = {
  isFetching: false,
  list: [],
  topSchoolsByState: {},
  topSuburbsByState: {},
  total: undefined,
  currentPage: undefined,
}

const schools = (
  state = initialState,
  { type, payload }: any
): SchoolsState => {
  switch (type) {
    case SEARCH_SCHOOLS:
    case GET_SCHOOL_BY_ID: {
      return mergeRight(state, {
        isFetching: true,
      })
    }
    case SEARCH_SCHOOLS_FAILED:
    case GET_SCHOOL_BY_ID_FAILED: {
      return mergeRight(state, {
        isFetching: false,
      })
    }
    case SEARCH_SCHOOLS_SUCCESS: {
      let list
      if (payload.currentPage === 1) {
        list = payload.schools
      } else {
        list = state.list.concat(payload.schools)
      }
      return mergeRight(state, {
        isFetching: false,
        list,
        count: payload.count,
        totalPages: Math.ceil(payload.count / SCHOOL.MAX_RESULTS_PER_PAGE),
        currentPage: payload.currentPage,
        isSSR: false,
      })
    }
    case UPLOAD_SCHOOL_LOGO_SUCCESS: {
      console.log('SUCCESS', payload)
      return state
    }
    case UPLOAD_SCHOOL_LOGO_FAILED: {
      console.log('FAILED', payload)
      return state
    }
    case GET_SCHOOL_BY_ID_SUCCESS: {
      return mergeRight(state, {
        isFetching: false,
        list: upsert(state.list, payload.school),
        isSSR: false,
      })
    }
    case GET_TOP_SCHOOLS_IN_STATES: {
      return mergeRight(state, {
        isFetching: true,
      })
    }
    case GET_TOP_SCHOOLS_IN_STATES_SUCCESS: {
      const { getTopSchoolsInStates } = payload
      return mergeDeepRight(state, {
        isFetching: false,
        topSchoolsByState: getTopSchoolsInStates.states,
      })
    }
    case GET_TOP_SCHOOLS_IN_STATES_FAILED: {
      return mergeRight(state, {
        isFetching: false,
      })
    }
    case GET_TOP_SUBURBS_IN_STATES: {
      return mergeRight(state, {
        isFetching: true,
      })
    }
    case GET_TOP_SUBURBS_IN_STATES_SUCCESS: {
      const { getTopSuburbsInStates } = payload
      return mergeDeepRight(state, {
        isFetching: false,
        topSuburbsByState: getTopSuburbsInStates.states,
      })
    }
    case GET_TOP_SUBURBS_IN_STATES_FAILED: {
      return mergeRight(state, {
        isFetching: false,
      })
    }
    case GET_SCHOOL_LISTINGS_BY_SCHOOL_ID:
      return state
    case GET_SCHOOL_LISTINGS_BY_SCHOOL_ID_SUCCESS: {
      const {
        getSchoolListingsByCoords: { listings },
        callbackParams: { schoolId } = {},
      } = payload
      return mergeRight(state, {
        list: [].concat(
          state.list.map((s) => {
            if (s.id === schoolId) {
              return {
                ...s,
                listings,
              }
            }
            return s
          })
        ),
      })
    }
    case CLEAR_SCHOOL_LIST: {
      return mergeRight(state, {
        list: [],
      })
    }
    default:
      return state
  }
}

export { schools, initialState }
