import { SCREEN_SIZE_UPDATE } from './actions'

export interface ScreenSizeState {
  size: any
  clientWidth: any
}

const screenSize = (
  state: any = {},
  { payload, type }: any
): ScreenSizeState => {
  switch (type) {
    case SCREEN_SIZE_UPDATE:
      return payload
    default:
      return state
  }
}

export { screenSize }
