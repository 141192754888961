import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import sagas from '../redux/sagas'
import { UtilHelper } from '../helpers/UtilHelper'
import { pick } from '../lib/ramda'
import { StateHelper } from '../helpers/StateHelper'

import { reducers, persistentStores } from '../redux/reducers'

const sagaMiddleware = createSagaMiddleware()
const appliedMiddleware = applyMiddleware(sagaMiddleware)

const createStore = () =>
  reduxCreateStore(
    combineReducers(reducers),
    StateHelper.hydrateState(),
    composeWithDevTools(appliedMiddleware)
  )

const store = createStore()

store.subscribe(
  UtilHelper.debounce(() => {
    const state = store.getState()
    StateHelper.persistState(pick(persistentStores || [], state))
  }, 300)
)

sagaMiddleware.run(sagas)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export { store }
