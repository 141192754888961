import React from 'react'
import { store } from '../../states/store'
import { updateScreenSize } from '../../states/screenSize/actions'
import { ScreenSizeHelper } from '../../helpers/ScreenSizeHelper'

const withAppInit = (Component) => {
  class WithAppInitClass extends React.Component {
    constructor() {
      super()
      this.prevBreakpoint = ''
    }

    componentDidMount() {
      this.windowResizeHandler()
      window.addEventListener('resize', this.windowResizeHandler.bind(this))
    }

    windowResizeHandler() {
      const { clientWidth } = window.document.body
      const breakpoint = ScreenSizeHelper.getBreakPoint(clientWidth)
      store.dispatch(updateScreenSize(breakpoint, clientWidth))
    }

    render() {
      return <div>{Component}</div>
    }
  }
  return <WithAppInitClass />
}

export { withAppInit }
