import { Auth } from '@aws-amplify/auth'
import jwtDecode from 'jwt-decode'
import { CONFIG } from '../config'

const { AWS_AUTH } = CONFIG

Auth.configure(AWS_AUTH)

const AuthHelper = Object.assign(Auth, {
  currentAuthenticatedUser: Auth.currentAuthenticatedUser,

  isAuthenticated: () => {
    const { store } = require('../states/store')
    const {
      currentUser: { userInfo },
    } = store.getState()
    if (!userInfo) {
      return false
    }
    const { jwtToken } = userInfo
    if (!jwtToken) {
      return false
    }
    const payload: any = jwtDecode(jwtToken)
    return payload.exp * 1000 > new Date().getTime()
  },

  getCurrentSession: Auth.currentSession,

  getJwtToken: async () => {
    const currentSession = await AuthHelper.getCurrentSession()
    return currentSession.getIdToken().getJwtToken()
  },

  getAuthHeader: async () => {
    try {
      const jwt = await AuthHelper.getJwtToken()
      return {
        Authorization: `Bearer ${jwt}`,
      }
    } catch (ex) {
      console.error('getAuthHeader Error', ex)
      return {}
    }
  },

  facebookSignIn: () => {
    // @ts-ignore
    AuthHelper.federatedSignIn({
      provider: 'Facebook',
    })
  },

  googleSignIn: () => {
    // @ts-ignore
    AuthHelper.federatedSignIn({
      provider: 'Google',
    })
  },
})

export { AuthHelper }
